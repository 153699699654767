import $ from "jquery";
import axios, { AxiosResponse } from "axios";
import { Modal } from "bootstrap";
import "toastr";

import * as axiosX from "../http/axios-extensions";
import Forms from "../utils/forms";
import * as HttpProblemDetails from "../http/http-problem-details";
import type { BasketAddRequestDto, BasketTemplateDto, Guid, InsufficientStockDetectedDto } from "./types";
import MiniBasket from "./mini-basket";
import PackagePromoter from "./package-promoter";
import OpenPromotePackageModalFactory from "./promote-package-modal";
import OpenInsufficientStockModalFactory from "./insufficient-stock-modal";
import AddToBasketComponent from "./add-to-basket";
import { BasketTemplateComponent } from "./basket-template";
import Multilang from "../utils/multilang.ts";
import ProductQuantityPicker from "../components/product-quantity-picker.ts";

type SelectListItem = {
  text: string;
  value: string;
};

enum AddToBasketTemplateStatus {
  AddedNewLine = "AddedNewLine",
  UpdatedExistingLine = "UpdatedExistingLine",
}

type LineResponse = {
  productName: string;
  quantity: number;
  status: AddToBasketTemplateStatus;
};

type AddToBasketTemplateResponse = {
  lineResponses: LineResponse[];
};

type BasketTemplatePriceInfo = {
  basketTemplateId: Guid;
  totalAmountNetText: string;
};

function isAddToBasketTemplateResponse(x: any): x is AddToBasketTemplateResponse {
  return typeof x === "object" && !!x.lineResponses && Array.isArray(x.lineResponses);
}

function isInsufficientStockDetectedDto(x: any): x is InsufficientStockDetectedDto<BasketAddRequestDto> {
  if (!x) return false;
  return (x as InsufficientStockDetectedDto<BasketAddRequestDto>).originalRequest !== undefined;
}

class BasketTemplateDelete {
  private static readonly modalSelector = "#delete-basket-template-modal";
  private static readonly modalBasketNameSelector = ".js-basket-template-to-delete--name";
  private static readonly modalBasketUidSelector = ".js-basket-template-to-delete--bid";

  static init() {
    $(document).on("click", ".js-confirm-delete-basket-template", () => {
      const $modal = $(this.modalSelector);
      const basketUniqueId = $modal.find(BasketTemplateDelete.modalBasketUidSelector).val();

      axios
        .post("/basket-templates/delete/" + basketUniqueId)
        .then(() => {
          $(`.js-basket-template[data-bid="${basketUniqueId}"]`).remove();
          $(`#basket_${basketUniqueId}`).remove();

          const modal = Modal.getOrCreateInstance($modal[0]);
          modal.hide();
        })
        .catch(axiosX.defaultCatch);
    });

    $(document).on("click", ".js-request-delete-basket-template", (e) => {
      const $clicked = $(e.currentTarget);
      const modalSelector = $clicked.data("bsTarget") ?? $clicked.attr("href");
      const $modal = $(modalSelector);
      fillDeleteConfirmationModal($modal, $clicked.data("bid"), $clicked.data("basketTemplateName"));
    });

    function fillDeleteConfirmationModal($modal: JQuery<HTMLElement>, basketUid: Guid, templateName: string) {
      $modal.find(BasketTemplateDelete.modalBasketNameSelector).html(templateName);
      $modal.find(BasketTemplateDelete.modalBasketUidSelector).val(basketUid);
    }

    $(this.modalSelector).on("hidden.bs.modal", function (e) {
      const $modal = $(e.target);
      $modal.find(BasketTemplateDelete.modalBasketNameSelector).html("");
      $modal.find(BasketTemplateDelete.modalBasketUidSelector).val("");
    });
  }
}

class BasketTemplateAddToShoppingBasket {
  static init() {
    $(document).on("click", ".js-add-basket-template-to-shopping-basket", (e) => {
      const $clicked = $(e.currentTarget);
      const basketUniqueId = $clicked.data("bid");

      let posting = axios.post("/basket-templates/add-to-basket/" + basketUniqueId);

      posting.then(() => {
        toastr.success(
          Multilang.getTranslation(
            "basket-template.toast.template-added-to-basket",
            "De lijst werd toegevoegd aan de winkelwagen.",
          ),
        );

        MiniBasket.update();
      });

      posting.catch(axiosX.defaultCatch);
    });
  }
}

class BasketTemplateSharing {
  static init() {
    $(document).on("click", ".js-start-sharing-template", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $clicked = $(e.currentTarget);
      const basketUniqueId = $clicked.data("bid");

      let posting = axios.post("/basket-templates/start-sharing/" + basketUniqueId);

      posting.then(() => {
        toastr.success(
          Multilang.getTranslation("basket-template.toast.template_share_started", "De lijst wordt nu gedeeld."),
        );

        $clicked
          .parents(".esc-basket-template-share-status")
          .removeClass("esc-basket-template-share-status--private")
          .addClass("esc-basket-template-share-status--sharedbyuser");
      });

      posting.catch(axiosX.defaultCatch);
    });

    $(document).on("click", ".js-stop-sharing-template", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $clicked = $(e.currentTarget);
      const basketUniqueId = $clicked.data("bid");

      let posting = axios.post("/basket-templates/stop-sharing/" + basketUniqueId);

      posting.then(() => {
        toastr.success(
          Multilang.getTranslation("basket-template.toast.template_share_stopped", "De lijst wordt niet meer gedeeld."),
        );

        $clicked
          .parents(".esc-basket-template-share-status")
          .removeClass("esc-basket-template-share-status--sharedbyuser")
          .addClass("esc-basket-template-share-status--private");
      });

      posting.catch(axiosX.defaultCatch);
    });
  }
}

class BasketTemplateLinesOnDemandLoader {
  static init() {
    const selector = ".js-load-basket-template-line-on-demand";

    $(selector).each(function (_index, element) {
      const collapsibleTarget = $(element).data("bs-target");
      const collapsibleEl = document.querySelector(collapsibleTarget);

      if (collapsibleEl !== null) {
        collapsibleEl.addEventListener("show.bs.collapse", function (event: any) {
          if (!event.target) return;
          const $target = $(event.target);
          const ajaxUrl = $target.data("esc-ajax-url");
          const ajaxTarget = $target.data("esc-ajax-target");

          if (ajaxUrl) {
            fetch(ajaxUrl)
              .then((r) => r.text())
              .then((h) => {
                $(ajaxTarget).html(h);
              })
              .finally(() => {
                // re-attach handlers for e.g. deleting line
                new BasketTemplateComponent(".js-basket-view.js-basket-view--template");
                ProductQuantityPicker.init(collapsibleEl);
                $target.removeAttr("data-esc-ajax-url");
              });
          }
        });
      }
    });
  }
}

class BasketTemplatesPriceChecker {
  static init() {
    if ($(".js-check-basket-template-prices").length === 0) {
      return;
    }

    const posting = axios.post("/basket-templates/check-prices");

    posting.then((response: AxiosResponse<any>) => {
      const infos = response.data as BasketTemplatePriceInfo[];

      for (const info of infos) {
        const bid = info.basketTemplateId;
        const selector = `.js-check-basket-template-prices[data-bid="${bid}"] .js-fill-in-basket-template-total-net-amount`;
        $(selector).html(info.totalAmountNetText);
      }
    });

    posting.catch(axiosX.defaultCatch);
  }
}

export default class BasketTemplateInteractions {
  private static readonly addToBasketModalId = "add-to-basket-template-modal";

  static init() {
    BasketTemplateDelete.init();
    BasketTemplateAddToShoppingBasket.init();
    BasketTemplateSharing.init();
    BasketTemplateLinesOnDemandLoader.init();
    BasketTemplatesPriceChecker.init();

    $(document).on("click", ".js-open-add-to-basket-template-modal", (e) => {
      const $clicked = $(e.currentTarget);
      const modalSelector = $clicked.data("bsTarget") ?? $clicked.attr("href");
      const $modal = $(modalSelector);
      this.setCopyFromBasketValues($clicked, $modal);
      this.setLinesValues($clicked, $modal);
      this.getBasketTemplateList();
      this.copySubmitFormId($clicked, $modal);
    });

    $(document).on("submit", `#${BasketTemplateInteractions.addToBasketModalId} form`, (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $form = $(e.target);
      const submitFormId = $form.data("submitFormId");

      if (!!submitFormId) {
        const $submitForm = $("#" + submitFormId);
        const $targetBid = $(`[name="targetBid"]`, $submitForm);
        const $targetBasketName = $(`[name="targetBasketName"]`, $submitForm);
        const modalBid = $(".js-existing-basket-templates-list", $form).val();

        if (!!modalBid) {
          $targetBid.val(modalBid);
        }

        const modalBasketName = $(".js-chosen-new-basket-template-name", $form).val();

        if (!!modalBasketName) {
          $targetBasketName.val(modalBasketName);
        }

        $submitForm.trigger("submit");
      } else {
        this.submitModalForm($form);
      }
    });

    $(`#${BasketTemplateInteractions.addToBasketModalId}`).on("hidden.bs.modal", function (e) {
      const $modal = $(e.target);
      $modal.find("input").val("");
      $modal.find(".js-existing-basket-templates-list option").remove();
      $modal.find(".js-btn-add-to-existing-basket-template").prop("disabled", true);

      const $form = $modal.find("form");
      $form.find("input").removeClass("is-invalid");
      $form.find(".invalid-feedback").text();
    });
  }

  private static submitModalForm($form: JQuery<HTMLElement>) {
    const $modal = $form.parents(".modal");

    const copyFromBasket = $(".js-add-to-basket-template--basket-uid", $form).val();

    if (!copyFromBasket) {
      // not copy from basket but adding a single line
      const quantity = parseInt(<string>$(".js-add-to-basket-template--quantity", $form).val());
      const min = $(".js-add-to-basket-template--moq", $form).val() as number;
      const step = $(".js-add-to-basket-template--ioq", $form).val() as number;
      const soq = $(".js-add-to-basket-template--soq", $form).val() as number;

      const pp = new PackagePromoter(min, step, soq);
      const suggestedQuantity = pp.suggestedQuantity(quantity);

      if (quantity !== suggestedQuantity) {
        openPromotePackageModal(soq, suggestedQuantity, postAddToBasketTemplate);
        return;
      }
    }

    postAddToBasketTemplate();

    function openPromotePackageModal(
      soq: number,
      suggestedQuantity: number,
      submitRequest: (quantity?: number) => void,
    ) {
      // two overlaying modals don't look that sweet
      // do not use 'modal.hide()' as this will empty the form
      // that way we can not post it
      const modal = Modal.getOrCreateInstance($modal[0]);
      modal.hide();

      let openModal = OpenPromotePackageModalFactory.create(
        soq,
        suggestedQuantity,
        () => {
          submitRequest(suggestedQuantity);
        },
        () => {
          submitRequest();
        },
        () => Forms.resetSubmitButtons($form.find("[type='submit']")),
      );

      return openModal();
    }

    function postAddToBasketTemplate(quantity?: number) {
      if (!!quantity) {
        $(".js-add-to-basket-template--quantity", $form).val(quantity);
      }

      const url = $form.attr("action") + "?" + $form.serialize();
      const submitButtons = $form.find("[type='submit']");

      axios
        .post(url)
        .then((response: any) => {
          let addedToBasket = response.data;

          if (isInsufficientStockDetectedDto(addedToBasket.value)) {
            return BasketTemplateInteractions.openInsufficientStockModal(addedToBasket.value);
          }

          if (isAddToBasketTemplateResponse(addedToBasket)) {
            addedToBasket.lineResponses.forEach((lineResponse) => {
              if (lineResponse.status === AddToBasketTemplateStatus.UpdatedExistingLine) {
                toastr.success(
                  `${lineResponse.productName} (# ${lineResponse.quantity})`,
                  Multilang.getTranslation(
                    "basket-template.toast.line_updated",
                    "Het product was reeds aanwezig in deze lijst. Wij hebben het door u geselecteerde aantal overgenomen.",
                  ),
                );
              }
            });
          }

          const modal = Modal.getOrCreateInstance($modal[0]);
          modal.hide();
        })
        .catch((error: any) => {
          if (error.response) {
            const details = HttpProblemDetails.asHttpProblemDetails(
              error.response.data,
              JSON.stringify(error.response),
              error.response.status,
            );

            if (details.status < 500) {
              $form.find(".invalid-feedback").text(details.detail);
              $form.find("input, select").addClass("is-invalid");
              return;
            }
          }

          axiosX.defaultCatch(error);
        })
        .finally(() => Forms.resetSubmitButtons(submitButtons));
    }
  }

  private static openInsufficientStockModal(data: InsufficientStockDetectedDto<BasketAddRequestDto>) {
    const createRequest = (
      encryptedProductId: string | undefined,
      quantity: number,
      basketUniqueId: Guid,
      dataLayerProduct: any,
    ) => {
      return {
        encryptedProductId: encryptedProductId,
        quantity: quantity,
        dataLayerProduct: dataLayerProduct,
        basketUniqueId: basketUniqueId,
      };
    };

    let openModal = OpenInsufficientStockModalFactory.create<BasketAddRequestDto>(
      AddToBasketComponent.addToBasket,
      AddToBasketComponent.addToBasket,
      (data) => Promise.resolve(data),
      createRequest,
      createRequest,
    );

    return openModal(data);
  }

  /**
   * Set parameters when adding to a basket template from a shopping basket.
   * @param $clicked
   * @param $modal
   */
  private static setCopyFromBasketValues($clicked: JQuery<HTMLElement>, $modal: JQuery<HTMLElement>) {
    const basketUid = $clicked.data("basketUid");
    // set this value on hidden input field of the modal:
    $modal.find(".js-add-to-basket-template--basket-uid").val(basketUid);
  }

  /**
   * Set parameters when adding one line to a basket template.
   * @param $clicked
   * @param $modal
   */
  private static setLinesValues($clicked: JQuery<HTMLElement>, $modal: JQuery<HTMLElement>) {
    const $form = $clicked.parents(".js-product").find("form.js-add-to-basket");
    const $input = $(`[name="quantity"]`, $form);

    const quantityValue = $input.val() as string;

    if (quantityValue === "") {
      return;
    }

    const encryptedProductId = $form.data("pid");
    const quantity = parseInt(quantityValue);

    const min = parseInt($input.prop("min")); // MOQ
    const step = parseInt($input.prop("step")); // IOQ
    const soq = $input.data("soq"); //SOQ

    // set these values on hidden input fields of the modal:
    $modal.find(".js-add-to-basket-template--pid").val(encryptedProductId);
    $modal.find(".js-add-to-basket-template--quantity").val(quantity);
    $modal.find(".js-add-to-basket-template--moq").val(min);
    $modal.find(".js-add-to-basket-template--ioq").val(step);
    $modal.find(".js-add-to-basket-template--soq").val(soq);
  }

  /**
   * Copy the submit form data attribute to the forms in the "add to template" modal
   * to support adding virtual basket lines to a basket template.
   * @param $clicked
   * @param $modal
   * @private
   */
  private static copySubmitFormId($clicked: JQuery<HTMLElement>, $modal: JQuery<HTMLElement>) {
    const submitFormId = $clicked.data("submitFormId");
    if (submitFormId) {
      $("form", $modal).data("submitFormId", submitFormId);
    }
  }

  private static getBasketTemplateList() {
    axios.get("/basket-templates").then(this.fillExistingBasketTemplatesList).catch(axiosX.defaultCatch);
  }

  private static fillExistingBasketTemplatesList(response: any) {
    let templates: BasketTemplateDto[] = response.data as BasketTemplateDto[];
    const linesLabel = $(".js-add-to-basket-template--lines-translation").text();

    const $dropdown = $(".js-existing-basket-templates-list");
    $dropdown.find("option").remove();

    let mostRecent = templates.sort(sortDescByUpdateTime).slice(0, 3).map(toSelectListItem); // TOP 3 most recently updated templates
    let alphabeticallyOrdered = templates.sort(sortAscByName).map(toSelectListItem);

    $.each(mostRecent, (_index, listItem) => {
      $dropdown.find("#optgroup_most-recent").append($("<option />").val(listItem.value).text(listItem.text));
    });

    $.each(alphabeticallyOrdered, (_index, listItem) => {
      $dropdown
        .find("#optgroup_alphabetically-ordered")
        .append($("<option />").val(listItem.value).text(listItem.text));
    });

    if (templates.length > 0) {
      $(".js-btn-add-to-existing-basket-template").prop("disabled", false);
    }

    function toSelectListItem(dto: BasketTemplateDto): SelectListItem {
      return { text: `${dto.name} - ${dto.numberOfLines} ${linesLabel}`, value: dto.id.value };
    }

    function sortAscByName(left: BasketTemplateDto, right: BasketTemplateDto) {
      if (left.name < right.name) return -1;
      if (left.name > right.name) return 1;
      return 0;
    }

    function sortDescByUpdateTime(left: BasketTemplateDto, right: BasketTemplateDto) {
      // https://gist.github.com/onpubcom/1772996
      if (left.updateTimeUtc > right.updateTimeUtc) return -1;
      if (left.updateTimeUtc < right.updateTimeUtc) return 1;
      return 0;
    }
  }
}
