import $ from "jquery";
import axios from "axios";
import * as axiosX from "../http/axios-extensions.ts";

export class ProductsBoughtTogetherExcluder {
  private readonly $excluders: JQuery<HTMLElement>;

  constructor() {
    this.$excluders = $(".js-product-bought-together .js-exclude-product-bought-together");
    this.$excluders.on("click", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $excludeButton = $(e.currentTarget);
      const mainProductId = $excludeButton.data("mainProductId");
      const boughtWithProductId = $excludeButton.data("boughtWithProductId");
      const postUrl = $excludeButton.data("postUrl");

      axios
        .post(postUrl, {
          mainProductId,
          boughtWithProductId,
        })
        .then(() => {
          $excludeButton.closest(".js-product-bought-together").remove();
        })
        .catch(axiosX.defaultCatch);
    });
  }
}

new ProductsBoughtTogetherExcluder();
