import $ from "jquery";
import axios from "axios";
import "toastr";

import NatchGtm, { EventNamesSchema } from "natch-gtm4";
import MiniBasket from "./mini-basket";
import * as axiosX from "../http/axios-extensions";
import SafeJSON from "../utils/safe-json";
import type { Guid, BasketAddRequestDto, BasketAddResponseDto, InsufficientStockDetectedDto } from "./types";
import { isInsufficientStockDetectedDtoOfTypeBasketAddRequestDto, isAddedToBasketDto } from "./type-guards";
import OpenInsufficientStockModalFactory from "./insufficient-stock-modal";
import QuantitySanitizer from "./quantity-sanitizer";
import PackagePromoter from "./package-promoter";
import OpenPromotePackageModalFactory from "./promote-package-modal";
import Forms from "../utils/forms";
import Multilang from "../utils/multilang.ts";

const addToBasketEvent = new Event("add-to-basket");

/**
 * Provides event listeners & handlers for
 * adding a product+quantity to a shopping basket.
 */
export default class AddToBasketComponent {
  private static readonly natchGtm = new NatchGtm(EventNamesSchema.CustomPrefixedWithEEC);
  private static readonly quantitySanitizer = new QuantitySanitizer();
  private static readonly elementSelector = "form.js-add-to-basket";

  static init() {
    $(document).on("submit", this.elementSelector, (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.submitAddToBasketForm(e.target);
    });

    // the element is a form without action attribute
    // before we attach jQuery/JS event, the form is submittable to the page itself, which would give an error message
    // therefore we initially hide the form and show it once we attached the submit event handler
    this.ensureIsVisible();
  }

  public static ensureIsVisible() {
    const $element = $(AddToBasketComponent.elementSelector);
    $element.removeClass("d-none");
  }

  public static addToBasket(sendData: BasketAddRequestDto) {
    if (sendData.quantity <= 0) {
      // use-case:
      // - when adding a product from template that has no stock and
      //   is not available for back-order
      // - and one chooses to 'take all stock of original and
      //   supplement with replacement product'
      // if this guard would not be present, this would result in a
      // zero-quantity basket line
      let theAny = undefined as any;
      return Promise.resolve(theAny);
    }

    const posting = axios.post("/basket/add", sendData);

    posting.then((response: any) => {
      const data: BasketAddResponseDto = response.data;
      console.log(data);

      if (isAddedToBasketDto(data)) {
        if (data.isShoppingBasket) {
          toastr.success(
            `${data.productName}<br>${data.quantity} ${data.unitOfMeasure}`,
            Multilang.getTranslation("basket.toast.added_to_basket", "Het product werd toegevoegd aan de winkelwagen."),
          );

          MiniBasket.update();
          AddToBasketComponent.reloadBasket();

          if (sendData.dataLayerProduct !== null) {
            ////console.log("Calling natchGtm.trackAddToCart", dataLayerProduct);
            AddToBasketComponent.natchGtm.trackAddToCart(sendData.dataLayerProduct, data.quantity);
          }
        } else {
          // added to template
          toastr.success(
            `${data.productName}<br>${data.quantity} ${data.unitOfMeasure}`,
            Multilang.getTranslation(
              "basket-template.toast.added_to_template",
              "Het product werd toegevoegd aan de lijst.",
            ),
          );
        }
      } else if (isInsufficientStockDetectedDtoOfTypeBasketAddRequestDto(data)) {
        this.openInsufficientStockModal(data);
      } else {
        this.openUnavailableProductModal();
      }
    });

    posting.catch(axiosX.defaultCatch);

    return posting;
  }

  private static reloadBasket() {
    if ($(".js-basket-view--shopping-basket").length > 0) {
      window.location.reload();
    }
  }

  public static submitAddToBasketForm(el: HTMLElement) {
    const $form = $(el);
    // $(form$).removeClass("input-validation-error");

    const $input = $('[name="quantity"]', $form);
    const quantityValue = $input.val() as string;

    if (quantityValue === "") {
      return;
    }

    if (!$form.valid()) {
      console.warn("Quantity not valid.");
      $form.addClass("input-validation-error");

      return;
    }

    const min = parseInt($input.prop("min")); // MOQ
    const step = parseInt($input.prop("step")); // IOQ
    const soq = $input.data("soq"); // SOQ
    const encryptedProductId = $form.data("pid");
    const basketUniqueId = $form.data("bid");
    const quantity = this.quantitySanitizer.sanitize(parseInt(quantityValue), min, step);

    if (typeof encryptedProductId === "undefined") {
      return;
    }

    // get GTM datalayer data in a safe way
    const jsonString = $form.attr("data-datalayer-cart-item");
    const dataLayerProduct = SafeJSON.parse(jsonString);

    let sendData: BasketAddRequestDto = {
      encryptedProductId: encryptedProductId,
      quantity: quantity,
      dataLayerProduct: dataLayerProduct,
      basketUniqueId: basketUniqueId,
    };

    $("input", $form).prop("readonly", true);

    const packagePromoter = new PackagePromoter(min, step, soq);
    const suggestedQuantity = packagePromoter.suggestedQuantity(quantity);

    if (quantity !== suggestedQuantity) {
      AddToBasketComponent.openPromotePackageModal(soq, suggestedQuantity, sendData, postAddToBasketRequest);
      return;
    }

    return postAddToBasketRequest(sendData);

    function postAddToBasketRequest(sendData: BasketAddRequestDto) {
      const posting = AddToBasketComponent.addToBasket(sendData);

      posting.finally(() => {
        Forms.resetSubmitButtons();
        $("input", $form).prop("readonly", false);
        el.dispatchEvent(addToBasketEvent);
      });
    }
  }

  private static openPromotePackageModal(
    //productName: string,
    soq: number,
    suggestedQuantity: number,
    sendData: BasketAddRequestDto,
    submitRequest: (req: BasketAddRequestDto) => void,
  ) {
    let openModal = OpenPromotePackageModalFactory.create(
      // productName,
      soq,
      suggestedQuantity,
      () => {
        sendData.quantity = suggestedQuantity;
        submitRequest(sendData);
      },
      () => submitRequest(sendData),
      () => Forms.resetSubmitButtons(),
    );

    return openModal();
  }

  private static openUnavailableProductModal() {
    const unavailableProductModalSelector = "#product-not-available-modal";
    const $modal = $(unavailableProductModalSelector);
    $modal.modal();
  }

  private static openInsufficientStockModal(data: InsufficientStockDetectedDto<BasketAddRequestDto>) {
    const createRequest = (
      encryptedProductId: string | undefined,
      quantity: number,
      basketUniqueId: Guid,
      dataLayerProduct: any,
    ) => {
      return {
        encryptedProductId: encryptedProductId,
        quantity: quantity,
        dataLayerProduct: dataLayerProduct,
        basketUniqueId: basketUniqueId,
      };
    };

    let openModal = OpenInsufficientStockModalFactory.create<BasketAddRequestDto>(
      this.addToBasket,
      this.addToBasket,
      (data) => Promise.resolve(data),
      createRequest,
      createRequest,
    );

    return openModal(data);
  }
}

AddToBasketComponent.init();
