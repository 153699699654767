import { createApp, defineAsyncComponent } from "vue";

/*
 * Place this on a page to render one of these components:
 * <div id="vue-hello-world" data-foo="bar"></div>
 */
export default class VueComponentLoader {
  private static vueComponents = [
    {
      componentName: "PasswordStrengthIndicator",
      elementId: "vue-password-strength-indicator",
      checkForVisibility: true,
    },
  ];

  static load() {
    for (let vueComponent of VueComponentLoader.vueComponents) {
      const mountElement = document.getElementById(vueComponent.elementId);

      if (mountElement !== null) {
        const doMount = vueComponent.checkForVisibility
          ? mountElement.offsetWidth > 0 || mountElement.offsetHeight > 0
          : true;

        if (doMount) {
          // console.log("vue mounting...", mountElement);
          const component = defineAsyncComponent(() => import(`../components/${vueComponent.componentName}.vue`));
          createApp(component, { ...mountElement.dataset }).mount("#" + vueComponent.elementId);
        }
      }
    }
  }
}
