import $ from "jquery";
import $$ from "./script/utils/double-dollar.ts";
import { Button, Tooltip } from "bootstrap";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "toastr";

import "./script/http/set-axios-defaults";
import Multilang from "./script/utils/multilang.ts";
import Styleguide from "./script/style-guide";
import ToggleSubmit from "./script/utils/toggle-submit";
import Forms from "./script/utils/forms";
import SearchPage from "./script/search-page";
import SearchAutoComplete from "./script/search-auto-complete";
import CheckoutContent from "./script/checkout-content";
import CheckoutDetails from "./script/checkout-details";
import NatchGtm, { EventNamesSchema } from "natch-gtm4";
import ProductPricing from "./script/product-pricing";
import BasketTemplateInteractions from "./script/baskets/basket-template-interactions";
import Impersonation from "./script/impersonation";
import CustomSwiper from "./script/components/custom-swiper";
import VueComponentLoader from "./script/vue-component-loader";

import "./script/baskets/shopping-basket";
import "./script/baskets/basket-template";
import "./script/baskets/add-to-basket";

import "./script/order-history";
import "./script/configurators";
import "./script/product-request-price";
import "./script/tracking";

import "./script/components/autocomplete";
import "./script/components/customer-product-code-box";
import "./script/components/file-upload";
import "./script/components/collapse-eye";
import "./script/components/copy-to-clipboard";
import "./script/product-quick-entry";
import "./script/components/product-3d-viewer";
import "./script/components/products-bought-together-excluder";
import "./script/components/input-date-localized";
import "./script/utils/history-go-back";

/* this declaration in types.d.ts isn't picked up, so putting it here */
declare global {
  interface Window {
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

// need jQuery on the window object for jquery validation
window.jQuery = $;

class App {
  static init() {
    console.log("🚀 App init called");
    console.log("🚀 jquery", $.fn.jquery);

    Multilang.load();

    const natchGtm = new NatchGtm(EventNamesSchema.CustomPrefixedWithEEC);
    natchGtm.processPage();

    // initialize Bootstrap components
    $$(`[data-bs-toggle="button"]`, (el) => new Button(el));
    $$(`[data-bs-toggle="tooltip"]`, (el) => new Tooltip(el));

    Styleguide.init();
    ToggleSubmit.init();
    Forms.init();
    ProductPricing.init();
    BasketTemplateInteractions.init();
    new Impersonation();
    CheckoutContent.init();
    CheckoutDetails.init();
    CustomSwiper.init();

    // vue components (loader)
    VueComponentLoader.load();

    const searchPage = new SearchPage(
      "#product-search-bar",
      "#paged-product-search-results",
      ".js-request-page-links-via-xhr>.pagination",
      ".js-search-filter-brand",
      [".js-product-search-summary", ".js-search-page--sort-order-switcher", "#product-search-filters"],
    );

    searchPage.init();
    new SearchAutoComplete("#product-search-bar input[name='q']");

    App.initFancybox();
    App.initToaster();
  }

  private static initFancybox() {
    // disable hash navigation ('#gallery' is no longer appended to the URL when opening a fancy image)
    // cf https://stackoverflow.com/a/43346241
    NativeFancybox.defaults.Hash = false;

    const opts = {};
    NativeFancybox.bind("[data-fancybox]", opts);
  }

  private static initToaster() {
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      // @ts-ignore
      onclick: null,
      // @ts-ignore
      showDuration: "3000",
      // @ts-ignore
      hideDuration: "3000",
      // @ts-ignore
      timeOut: "7000",
      // @ts-ignore
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }
}

App.init();
