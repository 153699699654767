import $ from "jquery";
import "toastr";

import BasketComponent from "./basket";
import type { Guid, BasketContent, BasketUpdateLineRequestDto, BasketLineQuantityUpdateResponse } from "./types";
import { isChangeLineQuantityResponse } from "./type-guards";
import smoothScrollTo from "../utils/smooth-scroller";
import Multilang from "../utils/multilang.ts";

export class BasketTemplateComponent extends BasketComponent {
  protected getReloadQueryString(basketUniqueId: Guid): string | undefined {
    return $.param({ bid: basketUniqueId });
  }

  constructor(elementSelector: string) {
    super(elementSelector);
  }

  protected changeBasketLineQuantity(el: HTMLElement, sendData?: BasketUpdateLineRequestDto) {
    let promise = super.changeBasketLineQuantity(el, sendData);

    promise.then((response: any) => {
      const data = response.data as BasketLineQuantityUpdateResponse;

      if (isChangeLineQuantityResponse(data)) {
        toastr.success(Multilang.getTranslation("basket-template.toast.template_updated", "De lijst werd bijgewerkt."));
      }
    });

    return promise;
  }

  protected removeBasketLineGroup(el: HTMLElement) {
    let promise = super.removeBasketLineGroup(el);

    promise.then((response: any) => {
      toastr.success(
        Multilang.getTranslation("basket-template.toast.product_updated", "Het product werd verwijderd de lijst."),
      );

      BasketTemplateComponent.updateTemplateBasketLines(response.data.updatedBasket);
    });

    return promise;
  }

  private static updateTemplateBasketLines(updatedBasket: BasketContent) {
    let updatedNumberOfLines = updatedBasket.basketLines.length;
    let basketUniqueId = updatedBasket.basketUniqueId;

    $(`.js-basket-template[data-bid="${basketUniqueId}"]`)
      .find(".js-basket-template-number-of-lines")
      .html(updatedNumberOfLines.toString());
  }
}

new BasketTemplateComponent(".js-basket-view.js-basket-view--template");

$(() => {
  if ($(".js-basket-templates-overview").length > 0) {
    const bid = new URL(location.href).searchParams.get("bid");
    const basketSelector = `#basket_${bid}`;
    $(basketSelector).removeClass("d-none");
    smoothScrollTo(basketSelector);
  }
});
