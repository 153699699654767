import $ from "jquery";
import datepickerFactory from "jquery-datepicker";
import datepickerFactoryNlBe from "jquery-datepicker/i18n/jquery.ui.datepicker-nl-BE";
import datepickerFactoryFr from "jquery-datepicker/i18n/jquery.ui.datepicker-fr";
import datepickerFactoryEnGb from "jquery-datepicker/i18n/jquery.ui.datepicker-en-GB";

/**
 * Polyfill for IE11 to support input[type="date"] with jQuery UI datepicker.
 */
export default class InputDatePolyfill {
  static init() {
    if (this.browserSupportsDateInput()) {
      return;
    }

    console.log(`Polyfilling input[type="date"] with jQuery UI datepicker`);
    datepickerFactory($);
    datepickerFactoryNlBe($);
    datepickerFactoryFr($);
    datepickerFactoryEnGb($);

    const $any: any = $;

    $(function () {
      const dateInputs = $(`input[type="date"]`);

      dateInputs.each((_index, element) => {
        const $element = $(element);
        let options = null;

        if (!!$element.prop("lang")) {
          let lang = $element.prop("lang");
          lang = lang === "fr-BE" ? "fr" : lang;
          options = $any.datepicker.regional[lang];
        }

        ($element as any).datepicker(options);
      });
    });
  }

  /** Return false if the browser does not support input[type="date"]. */
  private static browserSupportsDateInput() {
    const notADateValue = `not-a-date`;
    return $(`<input type="date" />`).val(notADateValue).val() !== notADateValue;
  }
}
