import $$ from "../utils/double-dollar.ts";
import { format } from "date-fns";

export default class InputDateLocalized {
  private readonly input: HTMLInputElement;

  constructor(element: HTMLElement) {
    this.input = element as HTMLInputElement;

    if (navigator.userAgent.toLowerCase().includes("firefox")) {
      // Firefox does not support setting content to ::before or ::after pseudo-elements inside <input> elements
      // because <input> cannot have content.
      // Rely on default browser behavior for Firefox instead.
      return;
    }

    this.reformat();
    this.setMask();
    this.input.addEventListener("change", this.reformat.bind(this));
    this.input.addEventListener("focus", this.removeMask.bind(this));
    this.input.addEventListener("blur", this.setMask.bind(this));
    this.input.querySelector("::-webkit-calendar-picker-indicator")?.addEventListener("click", this.input.showPicker);
  }

  private setMask() {
    // shows the localized date mask
    this.input.classList.add("input-date-localized-mask");
  }

  private removeMask() {
    // removes the localized date mask, making the date reachable & editable by tabbed navigation
    this.input.classList.remove("input-date-localized-mask");
  }

  static init() {
    $$("input[type='date']", (el) => new InputDateLocalized(el));
  }

  reformat() {
    if (!this.input.value) {
      return;
    }
    const formatString = this.input.getAttribute("data-date-format") ?? "dd/MM/yyyy";
    const formatted = format(new Date(this.input.value), formatString);
    this.input.setAttribute("data-date", formatted);
  }
}

InputDateLocalized.init();
