import $ from "jquery";
import { Modal } from "bootstrap";

const modalSelector = "#promote-package-modal";

export default class OpenPromotePackageModalFactory {
  public static create(
    //productName: string,
    soq: number,
    suggestedQuantity: number,
    onConfirmAction: () => void,
    onCancelAction: () => void,
    onCloseAction?: () => void,
  ) {
    return () => {
      const $modal = $(modalSelector);
      const template = $modal.find(".js-template").html();

      let html = template
        // .replace(
        //   /\{productName\}/g,
        //   `<span class="nt-promote-package-modal--product-name">${productName}</span>`
        // )
        .replace(/{soq}/g, soq.toString())
        .replace(/{suggestedQuantity}/g, suggestedQuantity.toString());

      $(".js-replaced").html(html);

      const modal = Modal.getOrCreateInstance($modal[0]);

      function modalClickHandlerWrapper(e: JQuery.ClickEvent, action: () => void) {
        e.preventDefault();
        e.stopImmediatePropagation();

        action();

        $modal.off("click");
        modal.hide();
      }

      $modal.on("click", ".js-confirm", (e) => {
        modalClickHandlerWrapper(e, () => onConfirmAction());
      });

      $modal.on("click", ".js-cancel", (e) => {
        modalClickHandlerWrapper(e, () => onCancelAction());
      });

      ($modal[0] as HTMLElement).addEventListener("hidden.bs.modal", () => {
        if (!!onCloseAction) {
          onCloseAction();
        }
      });

      modal.show();
    };
  }
}
