import $ from "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import InputDatePolyfill from "./input-date-polyfill";

export default class Forms {
  static init() {
    // on submit
    $("form").on("submit", (e) => {
      try {
        const $this = $(e.target);

        if ($this.valid() && $this.find(".js-order-history--export-button").length === 0) {
          // disable button on submit
          const $submitButtons = $this.find("[type=submit]");
          $submitButtons.each(Forms.disableButtons);

          const twinFormSelector = $this.data("twin-form-selector");

          if (twinFormSelector) {
            $(twinFormSelector).find("[type=submit]").each(Forms.disableButtons);
          }
        }
      } catch (e) {
        console.error(e);
      }
    });

    try {
      this.addExtraValidation();
    } catch (error) {
      console.error(error);
    }

    InputDatePolyfill.init();
  }

  static resetSubmitButtons($submitButtons?: JQuery<HTMLElement>) {
    $submitButtons ??= $("form [type='submit']");

    $submitButtons.each((_index, element) => {
      const $submitBtn = $(element);
      $submitBtn.html($submitBtn.data("originalhtml"));
      $submitBtn.removeAttr("disabled");
    });
  }

  private static disableButtons(_index: number, element: HTMLElement) {
    const $submitBtn = $(element);
    $submitBtn.attr("disabled", "disabled");
    $submitBtn.data("originalhtml", $submitBtn.html());
    $submitBtn.html('<i class="fas fa-circle-notch fa-spin"></i>');
  }

  private static addExtraValidation() {
    // mustbetrue
    $.validator.unobtrusive.adapters.addBool("mustbetrue", "required");

    // requiredif
    $.validator.addMethod("requiredif", (value, _element, params) => {
      // $params contains the other property, if that is filled in or truthy, this 'value' is required
      const $params = $(params);
      if (!$params) return true;
      if (!$params.val()) return true;
      const otherValue = `${$params.val()}`;
      if (otherValue.length === 0) return true;
      if (otherValue.toLowerCase() === "false") return true;
      return value.length !== 0;
    });

    $.validator.unobtrusive.adapters.add("requiredif", ["otherpropertyid"], function (options: any) {
      options.rules["requiredif"] = "#" + options.params.otherpropertyid;
      options.messages["requiredif"] = options.message;
    });

    // step
    $.validator.addMethod("step", (value, element, _params) => {
      // console.log("step validation", value, element, params)
      if (value == null || value == "") {
        return true;
      }

      const stepAttr = element.getAttribute("step");

      if (!stepAttr) {
        return true;
      }

      const step = parseFloat(stepAttr);

      if (step === 0) {
        return true;
      }

      const n = parseFloat(value);
      return n % step === 0;
    });

    $.validator.unobtrusive.adapters.add("step", ["step"], function (options: any) {
      // console.log("step - add adapter", options);
      options.rules["step"] = options.params;
      options.messages["step"] = options.message;
    });

    // daterange
    $.validator.addMethod("daterange", (value, _element, params) => {
      // value is of type string
      // value is date formatted as 'yyyy-MM-dd'
      // same for params.min and params.max
      if (value == null || value == "") {
        return true;
      }

      return params.min <= value && value <= params.max;
    });

    $.validator.unobtrusive.adapters.add("daterange", ["min", "max"], function (options: any) {
      // console.log("daterange - add adapter", options);
      options.rules["daterange"] = options.params;
      options.messages["daterange"] = options.message;
    });

    // numberrange
    $.validator.addMethod("numberrange", (value, _element, params) => {
      // console.log("numberrange validation", value, element, params)
      // value is of type string
      // same for params.min and params.max
      if (value == null || value == "") {
        return true;
      }

      const min = parseFloat(params.min);
      const max = parseFloat(params.max);
      const validMin = !!min ? min <= value : true;
      const validMax = !!max ? value <= max : true;
      const result = validMin && validMax;
      console.log("numberrange result", result);
      return result;
    });

    $.validator.unobtrusive.adapters.add("numberrange", ["min", "max"], function (options: any) {
      // console.log("numberrange - add adapter", options);
      options.rules["numberrange"] = options.params;
      options.messages["numberrange"] = options.message;
    });
  }
}
