import $ from "jquery";
import { addMonths, format } from "date-fns";
import debounce from "lodash-es/debounce";

export class OrderHistoryResetFilters {
  constructor() {
    $("form").on("click", ".js-order-history--reset-filters", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $form = $(e.delegateTarget);

      $form.find("input,select").each((_index, element) => {
        const $element = $(element);
        const id = $element.prop("id");
        if (!!id && id !== "Query_Page" && id !== "Query_ProductId") {
          const defaultValue = $element.data("defaultValue");
          $element.val(defaultValue).trigger("change");
          element.dispatchEvent(new Event("change")); // ensure change event is caught by vanilla js listeners
        }
      });
    });
  }
}

export class OrderHistoryDateFilterSanitizer {
  private static readonly defaultMonthsInWindow = 3;

  // private static readonly  maxMonthsInRange = 3;

  constructor() {
    const $form = $("form");

    let debouncedOnChangeFromDate = debounce(() => OrderHistoryDateFilterSanitizer.onChangeFromDate(), 1000);
    let debouncedOnChangeUntilDate = debounce(() => OrderHistoryDateFilterSanitizer.onChangeUntilDate(), 1000);

    $form.on("change", ".js-order-history--from-date", () => {
      debouncedOnChangeFromDate();
    });

    $form.on("change", ".js-order-history--until-date", () => {
      debouncedOnChangeUntilDate();
    });

    // Prevent form submission when Enter is pressed in the datepicker fields
    $form.on("keydown", ".js-order-history--from-date, .js-order-history--until-date", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    });
  }

  private static onChangeFromDate() {
    const range = this.getSelectedRange();
    const allowed = this.getAllowedRange();

    if (range.start > range.end) {
      this.setUntilDate(addMonths(range.start, this.defaultMonthsInWindow));
    }

    if (range.start < allowed.start) {
      this.setFromDate(allowed.start);
    }

    // const threeMonthsAfterFrom = addMonths(range.start, maxMonthsInRange);
    // if (threeMonthsAfterFrom < range.end) {
    //   OrderHistoryDateFilterSanitizer.setUntilDate(threeMonthsAfterFrom);
    //   return;
    // }
  }

  private static onChangeUntilDate() {
    const range = OrderHistoryDateFilterSanitizer.getSelectedRange();

    if (range.start > range.end) {
      OrderHistoryDateFilterSanitizer.setFromDate(addMonths(range.end, -this.defaultMonthsInWindow));
    }

    // const threeMonthsBeforeUntil = addMonths(range.end, -maxMonthsInRange);
    // if (range.start < threeMonthsBeforeUntil) {
    //   OrderHistoryDateFilterSanitizer.setFromDate(threeMonthsBeforeUntil);
    //   return;
    // }
  }

  private static yyyMMdd(date: Date | number) {
    return format(date, "yyyy-MM-dd");
  }

  private static setFromDate(value: Date | number) {
    $(".js-order-history--from-date").val(OrderHistoryDateFilterSanitizer.yyyMMdd(value));
  }

  private static setUntilDate(value: Date | number) {
    $(".js-order-history--until-date").val(OrderHistoryDateFilterSanitizer.yyyMMdd(value));
  }

  private static getSelectedRange(): any {
    const from = $(".js-order-history--from-date").val()?.toString() ?? "";
    const until = $(".js-order-history--until-date").val()?.toString() ?? "";
    return { start: new Date(from), end: new Date(until) };
  }

  private static getAllowedRange(): any {
    const from = $(".js-order-history--from-date").attr("min")?.toString();
    const until = $(".js-order-history--until-date").attr("max")?.toString();
    let start = !!from ? new Date(from) : new Date(0); // fallback to 1970-01-01
    let end = !!until ? new Date(until) : new Date(); // fallback to now
    return { start: start, end: end };
  }
}

export class OrderHistoryExportButton {
  constructor() {
    const $exportButton = $(".js-order-history--export-button");

    // jQuery was too slow - prevent glitch
    $(document).ready(() => {
      $exportButton.removeClass("d-none");
    });

    // When query changes, hide button
    const $form = $("form");

    $form
      .find(":input")
      .not($exportButton)
      .on("change", () => {
        $exportButton.addClass("d-none");
      });

    $(".page-link:not([active],[disabled])").on("click", () => {
      $exportButton.addClass("d-none");
    });
  }
}

export class OrderLineTracking {
  constructor() {
    $(".js-highlight-order-lines").on("click", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const $el = $(e.currentTarget);
      const trackingCode = $el.data("trackingCode");

      const wasSelected = $el.hasClass("btn-success");

      if (wasSelected) {
        $el.removeClass("btn-success").addClass("btn-outline-primary");
        $(`.js-order-line.tracking-code-${trackingCode}`).removeClass("table-success");
      } else {
        $el.removeClass("btn-outline-primary").addClass("btn-success");
        $(`.js-order-line.tracking-code-${trackingCode}`).addClass("table-success");

        const $section = $(".js-track-and-trace-section");

        $("html").animate(
          {
            scrollTop: $section.offset()?.top,
          },
          {
            duration: 1000,
          },
        );
      }
    });
  }
}

export class OrderLineFiltering {
  constructor() {
    $(() => {
      const $toggleFilterLines = $(".js-filter-order-lines--not-delivered input[type='checkbox']");
      $toggleFilterLines.on("change", (e) => {
        const $checkbox = $(e.currentTarget);
        const isChecked = $checkbox.is(":checked");
        const currentUrl = new URL(window.location.href);
        const includeDeliveredLines = isChecked ? "false" : "true";

        // add query parameter to url
        currentUrl.searchParams.set("includeDeliveredLines", includeDeliveredLines);
        window.history.replaceState(null, "", currentUrl.toString());

        $toggleFilterLines.prop("disabled", true);

        fetch(currentUrl.toString())
          .then((r) => r.text())
          .then((h) => {
            const $html = $("<div/>").append(h);
            const selectors = [".js-order-history--export-button-wrapper", ".js-orders-search-results"];
            for (const selector of selectors) {
              const $newResults = $html.find(selector);
              $(selector).html($newResults.html() ?? "");
            }
            OrderLinesOnDemandLoader.init();
          })
          .finally(() => {
            $toggleFilterLines.prop("disabled", false);
          });
      });
    });
  }
}

class OrderLinesOnDemandLoader {
  static init() {
    const $orderLines = $(".js-order-lines");
    $orderLines.each((_index, element) => {
      element.addEventListener("show.bs.collapse", (event) => {
        if (!event.target) return;
        const $target = $(event.target as HTMLElement);
        this.loadOrderLines($target);
      });
    });
    $orderLines.each((_index, element) => {
      const $element = $(element);
      if ($element.hasClass("show")) {
        setTimeout(() => {
          this.loadOrderLines($(element));
        }, _index * 150);
      }
    });
  }

  private static loadOrderLines($target: JQuery<HTMLElement>) {
    const ajaxUrl = $target.attr("data-esc-ajax-url"); // read from attribute, not from data store, such that it can be unset
    const ajaxTarget = $target.data("esc-ajax-target");
    if (ajaxUrl) {
      fetch(ajaxUrl)
        .then((r) => {
          const hdr = r.headers.get("X-Order-Line-Count");
          const count = parseInt(hdr ?? "0");
          if (Number.isNaN(count) || count === 0) {
          } else {
            $(".js-order-history--export-button").removeClass("d-none");
          }
          return r.text();
        })
        .then((h) => {
          $(ajaxTarget).html(h);
        })
        .finally(() => {
          $target.removeAttr("data-esc-ajax-url");
        });
    }
  }
}

new OrderHistoryResetFilters();
new OrderHistoryDateFilterSanitizer();
new OrderHistoryExportButton();
new OrderLineTracking();
new OrderLineFiltering();
OrderLinesOnDemandLoader.init();
