import $ from "jquery";

$(".js-product-request-price").on("click", (e) => {
  const $quantityInputElement = $(".js-order-box input[name='quantity']");
  const quantity = $quantityInputElement.val() as string;
  const $priceRequestButton = $(e.currentTarget);
  const subjectTemplate = $priceRequestButton.attr("data-subject-template") as string;
  const contactEmailAddress = $priceRequestButton.attr("data-contact") as string;
  const subject = subjectTemplate.replace("Quantity", quantity);
  window.location.href = `mailto:${contactEmailAddress}?subject=${subject}`;
});
