export default class QuantitySanitizer {
  constructor() {}

  public sanitize(quantityInput: number, min: number, increment: number) {
    let result = Math.max(quantityInput, min);
    if (result % increment !== 0) {
      let quotient = result / increment;
      let multiplier = Math.floor(quotient) + 1;
      result = multiplier * increment;
    }
    return result;
  }
}
