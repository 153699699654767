import $$ from "../utils/double-dollar";
import $ from "jquery";

$$(".js-collapse-eye", (el) => {
  const hel = el as HTMLElement;
  if (!hel) return;

  const id = hel.dataset?.followsCollapse;
  if (!id) return;

  const $collapsible = $(`#${id}`);
  $collapsible.on("hide.bs.collapse", function (e) {
    closeEye(e.eventPhase ?? 0);
  });

  $collapsible.on("show.bs.collapse", function (e) {
    openEye(e.eventPhase ?? 0);
  });

  function closeEye(eventPhase: number) {
    if (eventPhase === Event.BUBBLING_PHASE) return;
    hel.classList.remove("open");
    hel.classList.add("closed");
    if (hel.classList.contains("changed")) {
      hel.classList.remove("changed");
      hel.classList.add("initial");
    } else {
      hel.classList.remove("initial");
      hel.classList.add("changed");
    }
  }

  function openEye(eventPhase: number) {
    if (eventPhase === Event.BUBBLING_PHASE) return;
    hel.classList.remove("closed");
    hel.classList.add("open");
    if (hel.classList.contains("changed")) {
      hel.classList.remove("changed");
      hel.classList.add("initial");
    } else {
      hel.classList.remove("initial");
      hel.classList.add("changed");
    }
  }
});
