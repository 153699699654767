import axios from "axios";
import $ from "jquery";

import * as axiosX from "./http/axios-extensions";
import Forms from "./utils/forms";
import ProductPricing from "./product-pricing";
import AddToBasketComponent from "./baskets/add-to-basket";
import { Product3dViewer } from "./components/product-3d-viewer.ts";
import CustomSwiper from "./components/custom-swiper.ts";
import $$ from "./utils/double-dollar.ts";
import { Tooltip } from "bootstrap";
import ProductQuantityPicker from "./components/product-quantity-picker.ts";

interface LoadPageOptions {
  pushHistory?: boolean;
  updateBrandRefiner?: boolean;
  updateDOM?: boolean;
}

export default class SearchPage {
  private readonly searchBarSelector: string;
  private readonly searchResultsSelector: string;
  private readonly pagerSelector: string;
  private readonly brandRefinerSelector: string;
  private readonly otherUpdatePartSelectors: string[] | undefined;

  private contextHeaders: any = {};
  private pageMap: any = {};

  public constructor(
    searchBarSelector: string,
    searchResultsSelector: string,
    pagerSelector: string,
    brandRefinerSelector: string,
    otherUpdatePartSelectors?: Array<string>,
  ) {
    this.searchBarSelector = searchBarSelector;
    this.searchResultsSelector = searchResultsSelector;
    this.pagerSelector = pagerSelector;
    this.brandRefinerSelector = brandRefinerSelector;
    this.otherUpdatePartSelectors = otherUpdatePartSelectors;
  }

  public init = () => {
    const $body = $("body");
    this.setContextHeaders($body);
    this.logSortOrderDebugInfo($body);

    if (window.location.hash === "#allbrands") {
      this.showAllBrands();
    }

    this.setToggleButtonVisibility();

    // only set hidden input fields directly when not on search page:
    $(`${this.searchBarSelector}:not(.js-on-search-page)`).on("click", ".nt-btn-search-toggle", (event) => {
      const $toggle = $(event.currentTarget);
      let hasClassBeforeThisEvent = $toggle.hasClass("active");
      let isActive = hasClassBeforeThisEvent ? 0 : 1;
      let fieldname = $toggle.data("ifActiveSetFieldTo-1");
      let $field = $(`input[name='${fieldname}'`);
      $field.val(isActive);
    });

    const $searchBarOnSearchPage = $(`${this.searchBarSelector}.js-on-search-page`);

    // only set hidden input fields directly when not on search page:
    $searchBarOnSearchPage.on("click", ".nt-btn-search-toggle:not(.disabled)", () => {
      const $form = $(`${this.searchBarSelector}.js-on-search-page form`);

      // small timeout is used so the active class of the buttons can get updated.
      // if we would not add this small timeout than it would not work as
      // submitting the form goes faster than updating the active class.
      setTimeout(() => $form.trigger("submit"), 100);
    });

    // event handler for navigating back in (client-side) history
    // this will make sure that the data for the URL is loaded
    window.addEventListener("popstate", (): void => {
      this.loadPage(document.location.href, { pushHistory: false }).then((response: any) => {
        const $html = $("<div/>").append(response.data);
        this.replaceOnPage($html, this.searchBarSelector);
      });
    });

    $(this.pagerSelector).on("click", "a.page-link", (event) => {
      event.preventDefault();
      // take currentTarget, is always the anchor tag (even when clicking on icon)
      let url = $(event.currentTarget).attr("href");

      if (typeof url === "undefined") {
        return false;
      }

      this.loadPage(url, { updateBrandRefiner: false });
    });

    // only send search query from AJAX when on search page:
    $searchBarOnSearchPage.on("submit", "form", (event) => {
      event.preventDefault();

      const $form = $(event.target);

      // ensure that the auto complete suggestions dropdown is closed on form submit,
      // such that it will not remain opened
      const $q = $form.find(`input[name='q']`);
      $q.trigger("blur");

      // set hidden input fields "f" and "s"
      $(".nt-btn-search-toggle").each((_index, element) => {
        const $toggle = $(element);
        let hasActiveClass = $toggle.hasClass("active");
        let isActive = hasActiveClass ? 1 : 0;
        let fieldName = $toggle.data("ifActiveSetFieldTo-1");
        let $field = $(`input[name='${fieldName}'`);
        $field.val(isActive);
      });

      const searchTerm = `${$q.val()}`;
      $form.data("previous-search-term", searchTerm);

      const url = $form.attr("action") + "?" + $form.serialize();
      this.loadPage(url);
    });

    $body.on("click", ".js-apply-search-filter-brand", (event) => {
      event.preventDefault();

      const $refiner = $(event.target);
      const brandId = $refiner.data("brand-category-id");
      const $field = $(`input[name='b']`);
      $field.val(brandId);

      const form$ = $(`${this.searchBarSelector} form`);
      const url = form$.attr("action") + "?" + form$.serialize();
      this.loadPage(url);
    });

    $body.on("click", ".js-remove-search-filter-brand", (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      $(this.searchBarSelector).find(`input[name='b']`).val("");

      const form$ = $(`${this.searchBarSelector} form`);
      const url = form$.attr("action") + "?" + form$.serialize();
      this.loadPage(url);
    });

    $body.on("click", ".js-show-all-brand-filters", (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.showAllBrands();
      history.replaceState(undefined, "", "#allbrands");
    });

    $body.on("click", ".js-hide-tail-brands", (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      this.hideAllBrands();
      history.replaceState(undefined, "", "#");
    });

    $body.on("click", ".js-load-product-detail", (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();

      const $element = $(event.currentTarget);
      const href = $element.attr("href");
      if (!href) return;
      this.loadProductDetailPage(href);
    });

    $body.on("change", ".js-sort-order-options", (event) => {
      const sortOrder = event.target.value;
      const $form = $(`${this.searchBarSelector}.js-on-search-page form`);
      $form.find(`input[name='so']`).val(sortOrder?.toString() ?? "");
      $form.trigger("submit");
    });
  };

  private setToggleButtonVisibility() {
    let valStock = $(`input[name='canFilterProductsInStock']`)?.val()?.toString();
    if (valStock === "0") {
      $(".js-in-stock-only").addClass("disabled");
    }

    let valPurchased = $(`input[name='canFilterPreviouslyPurchasedProducts']`)?.val()?.toString();
    if (valPurchased === "0") {
      $(".js-previously-purchased-only").addClass("disabled");
    }
  }

  private showAllBrands() {
    $(".js-search-filter-brand").removeClass("esc-search-filter-brand--only-top-matches");
  }

  private hideAllBrands() {
    $(".js-search-filter-brand").addClass("esc-search-filter-brand--only-top-matches");
  }

  private loadPage = (url: string, options?: LoadPageOptions) => {
    const params = new URLSearchParams(url);
    let p = params.get("p");
    let page = parseInt("" + p);

    const posting = axios.post(url, this.pageMap[page ?? 1], { headers: this.contextHeaders });

    posting.then((response: any) => {
      this.processResponseData(response.data, url, options);
      return response.data;
    });

    posting.catch(axiosX.defaultCatch);
    posting.finally(() => {
      Forms.resetSubmitButtons();
    });

    // no need to set title with X-AJAXPAGETITLE header because title remains the same ??

    // return promise
    return posting;
  };

  private processResponseData(data: string | Object, url: string, options?: LoadPageOptions) {
    const push = options?.pushHistory ?? true;
    const updateBrandRefiner = options?.updateBrandRefiner ?? true;
    const updateDOM = options?.updateDOM ?? true;

    if (typeof data === "string") {
      // data = HTML page
      const data_ = data as string;
      const $html = $("<div/>").append(data_);
      this.replacePartsOnPage($html, updateDOM, updateBrandRefiner);
      this.setContextHeaders($html);
      this.logSortOrderDebugInfo($html);

      if (push) {
        // add to navigation history
        history.pushState(null, document.title, url);
      }
    } else if (typeof data === "object") {
      // there is only one search result => open product detail with returned redirectUrl
      const data_ = data as any;
      document.location = data_.redirectUrl;
      return;
    }
  }

  private logSortOrderDebugInfo($html: JQuery<HTMLElement>) {
    const json = $html.find("#product-search-sort-order-debug-info").val();
    if (!json) return;

    const info = JSON.parse(json as string);
    console.table(info);
  }

  private setContextHeaders = ($html: JQuery<HTMLElement>) => {
    this.contextHeaders = {};

    $html.find("input.js-set-http-header").each((_index, element) => {
      let $element = $(element);
      let header = $element.data("forHttpHeader");

      if (!!header) {
        this.contextHeaders[header] = $element.val();

        if (header === "X-ProductSearch-Results") {
          this.pageMap = JSON.parse("" + $element.val());
        }
      }
    });
  };

  private replacePartsOnPage($html: JQuery<HTMLElement>, updateDOM: boolean, updateBrandRefiner: boolean) {
    if (updateBrandRefiner) {
      this.replaceOnPage($html, this.brandRefinerSelector);
    }

    if (!updateDOM) return;

    if ($(this.searchResultsSelector).length === 0) {
      this.replaceOnPage($html, ".js-top-level-container");
      this.refreshComponents();
      return;
    }

    this.replaceOnPage($html, this.pagerSelector);

    $(this.searchResultsSelector).fadeOut({
      duration: "fast",
      done: () => {
        this.replaceOnPage($html, this.searchResultsSelector);
        $(this.searchResultsSelector).fadeIn({
          duration: "fast",
          done: () => {
            this.refreshComponents();
          },
        });
      },
    });

    this.otherUpdatePartSelectors?.forEach((value, _index, _array) => {
      this.replaceOnPage($html, value);
    });
  }

  private refreshComponents() {
    ProductPricing.init();
    $$(`[data-bs-toggle="tooltip"]`, (el) => new Tooltip(el));
    AddToBasketComponent.ensureIsVisible();
    CustomSwiper.init();
    new Product3dViewer();
    ProductQuantityPicker.init();
  }

  private replaceOnPage = ($html: JQuery<HTMLElement>, selector: string) => {
    const $newResults = $html.find(selector);
    // swap results on page with those from the XHR request:
    $(selector).html($newResults.html() ?? "");
  };

  private loadProductDetailPage(url: string) {
    axios.post(url).then((response: any) => {
      const html = response.data as string;

      if (html === null) {
        return;
      }

      const $html = $("<div/>").append(html);
      this.replaceOnPage($html, ".js-top-level-container");
      const doc = new DOMParser().parseFromString(html, "text/html");
      document.title = doc.title;
      history.pushState(null, document.title, url);
      this.refreshComponents();
    });
  }
}
