import * as bootstrap from "bootstrap";
import jQuery from "jquery";

/*
based on https://github.com/Honatas/bootstrap-4-autocomplete/blob/master/src/bootstrap-4-autocomplete.ts
Added both dividers and headers to the dropdown:
https://getbootstrap.com/docs/4.0/components/dropdowns/#menu-dividers
https://getbootstrap.com/docs/4.0/components/dropdowns/#menu-headers
*/

export interface DividedAutocompleteItem {
  value: string;
  label: string;
  iconClass: string | null;
}

export interface DividedAutocompleteOptions {
  dropdownOptions?: bootstrap.Dropdown.Options;
  dropdownClass?: string | string[];
  highlightClass?: string | string[];
  highlightTyped?: boolean;
  label?: string;
  maximumItems?: number;
  onSelectItem?: (value: string, label: string, element: HTMLElement) => void;
  source?: { [key: string]: DividedAutocompleteItem[] };
  threshold?: number;
  value?: string;
}

export interface AutocompleteWithDividers extends JQuery {
  autocompleteWithDividers(options: DividedAutocompleteOptions): JQuery;
}

(function ($) {
  let defaults: DividedAutocompleteOptions = {
    threshold: 4,
    maximumItems: 5,
    highlightTyped: true,
    highlightClass: "text-primary",
  };

  function createItem(lookup: string, item: DividedAutocompleteItem, opts: DividedAutocompleteOptions): string {
    let label: string;

    if (opts.highlightTyped) {
      const idx = item.label.toLowerCase().indexOf(lookup.toLowerCase());
      label =
        item.label.substring(0, idx) +
        '<span class="' +
        expandClassArray(opts.highlightClass ?? "") +
        '">' +
        item.label.substring(idx, idx + lookup.length) +
        "</span>" +
        item.label.substring(idx + lookup.length, item.label.length);
    } else {
      label = item.label;
    }

    let iconHtml: string = "";

    if (item.iconClass) {
      iconHtml = `<i class="${item.iconClass}" />`;
    }

    return `<button type="button" class="dropdown-item" data-ac-value="${encodeURIComponent(
      item.value,
    )}" data-ac-label="${encodeURIComponent(item.label)}">${label} ${iconHtml}</button>`;
  }

  function expandClassArray(classes: string | string[]): string {
    if (typeof classes == "string") {
      return classes;
    }

    if (classes.length == 0) {
      return "";
    }

    let ret = "";
    for (const clas of classes) {
      ret += clas + " ";
    }

    return ret.substring(0, ret.length - 1);
  }

  function createItems(field: JQuery<HTMLElement>, dropdown: bootstrap.Dropdown, opts: DividedAutocompleteOptions) {
    const lookup = (field.val() as string).trim();

    if (lookup.length < (opts.threshold ?? 0)) {
      dropdown.hide();
      return 0;
    }

    const element = field.next();
    element.html("");

    let count = 0;
    const source = opts.source ?? {};
    const categoryNames = Object.keys(source);

    for (let i = 0; i < categoryNames.length; i++) {
      const categoryName = categoryNames[i];
      const category = source[categoryName];

      if (category.length <= 0) {
        continue;
      }

      if (i > 0) {
        element.append(`<div class="dropdown-divider"></div>`);
      }

      if (categoryName) {
        element.append(`<h6 class="dropdown-header">${categoryName}</h6>`);
      }

      for (let i = 0; i < category.length; i++) {
        const item = category[i] as DividedAutocompleteItem;

        if (item.label.toLowerCase().indexOf(lookup.toLowerCase()) >= 0) {
          element.append(createItem(lookup, item, opts));

          const maxItems = opts.maximumItems ?? 0;
          if (maxItems > 0 && ++count >= maxItems) {
            break;
          }
        }
      }
    }

    // option action
    field
      .next()
      .find(".dropdown-item")
      .on("click", function () {
        field.val($(this).text());

        if (opts.onSelectItem) {
          opts.onSelectItem(
            decodeURIComponent($(this).data("ac-value")),
            decodeURIComponent($(this).data("ac-label")),
            field[0],
          );
        }
      });

    return element.children().length;
  }

  let autocompleteWithDividers = function (this: JQuery, options: DividedAutocompleteOptions) {
    // merge options with default
    let opts: DividedAutocompleteOptions = {};
    $.extend(opts, defaults, options);

    let _field = $(this);
    let _dropdown = new bootstrap.Dropdown(_field[0]);

    // clear previously set autocomplete
    _field.parent().removeClass("dropdown");
    _field.removeAttr("data-bs-toggle");
    _field.removeClass("dropdown-toggle");
    _field.parent().find(".dropdown-menu").remove();
    _dropdown.dispose();

    // attach dropdown
    _field.parent().addClass("dropdown");
    _field.attr("data-bs-toggle", "dropdown");
    _field.addClass("dropdown-toggle");

    // attach dropdown class
    const id = _field.attr("id");
    const dropdown = $(`<div class="dropdown-menu w-100 overflow-hidden" aria-labelledby="${id}"></div>`);
    if (opts.dropdownClass) dropdown.addClass(opts.dropdownClass);
    _field.after(dropdown);

    _dropdown = new bootstrap.Dropdown(_field[0], opts.dropdownOptions);

    this.off("click.autocomplete").on("click", undefined, "click.autocomplete", function (e) {
      if (createItems(_field, _dropdown, opts) === 0) {
        // prevent show empty
        e.stopPropagation();
        _dropdown.hide();
      }
    });

    // show options
    this.off("keyup.autocomplete").on("keyup", undefined, "keyup.autocomplete", function (e) {
      if (e.key === "ArrowDown") {
        // drop out of input text field and into dropdown
        dropdown.children(".dropdown-item").first().trigger("focus");
        return;
      }

      if (e.key === "Escape") {
        // hide dropdown
        _dropdown.hide();
        return;
      }

      const itemCount = createItems(_field, _dropdown, opts);

      if (itemCount > 0) {
        _dropdown.show();
      }
    });

    return this;
  };

  jQuery.fn.extend({
    autocompleteWithDividers: autocompleteWithDividers,
  });
})(jQuery);
