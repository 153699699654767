class ClipboardCopier {
  constructor() {}

  public copyToClipboard(input: HTMLInputElement) {
    const text = input.value;
    this.tryCopyWithNavigator(text)
      .catch(() => this.tryDeprecatedCopy(input))
      .catch(() => {});
  }

  private tryCopyWithNavigator(text: string) {
    if (!window.navigator) return Promise.reject();

    // @ts-ignore ("clipboard-write" is unknown)
    const permissionQuery = navigator.permissions.query({ name: "clipboard-write" });
    return permissionQuery
      .then((result) => {
        if (["granted", "prompt", "fulfilled"].indexOf(result.state) >= 0) {
          return navigator.clipboard.writeText(text).then(
            () => {
              return Promise.resolve();
            },
            () => {
              return Promise.reject();
            },
          );
        }
        return Promise.reject();
      })
      .catch((reason) => {
        return Promise.reject(reason);
      });
  }

  private tryDeprecatedCopy(input: HTMLInputElement) {
    if (!document.execCommand) return Promise.reject();

    input.select();
    document.execCommand("copy");
    return Promise.resolve();
  }
}

class CopyToClipboardAddonLoader {
  constructor() {
    const copyButtons = document.querySelectorAll<HTMLElement>(".js-copy-to-clipboard");
    if (copyButtons.length > 0) this.addClickListeners(copyButtons);
  }

  private addClickListeners(copyButtons: NodeListOf<HTMLElement>) {
    const copier = new ClipboardCopier();
    copyButtons.forEach((btn) => {
      btn.addEventListener("click", () => {
        const srcElementId = btn.dataset.copyToClipboardId;
        if (!!srcElementId) {
          const srcElement = document.getElementById(srcElementId) as HTMLInputElement;
          if (!!srcElement) {
            srcElement.select();
            copier.copyToClipboard(srcElement);
          }
        }
      });
    });
  }
}

new CopyToClipboardAddonLoader();
