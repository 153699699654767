import $ from "jquery";
import axios from "axios";
import debounce from "lodash-es/debounce";

import * as axiosX from "../http/axios-extensions";
import QuantitySanitizer from "./quantity-sanitizer";

import type {
  Guid,
  BasketUpdateLineRequestDto,
  BasketLineQuantityUpdateResponse,
  BasketAddRequestDto,
  BasketAddResponseDto,
  UpdatedBasketResponse,
  InvalidProduct,
} from "./types";
import {
  isUpdatedBasketResponse,
  isInsufficientStockDetectedDtoOfTypeBasketUpdateLineRequestDto,
  isAddedToBasketDto,
} from "./type-guards";
import AddToBasketComponent from "./add-to-basket";
import OpenInsufficientStockModalFactory from "./insufficient-stock-modal";

type BasketLineRemoveRequestDto = {
  basketLineGroupGuid: Guid;
  basketUniqueId?: Guid;
};

type BasketLineEnsembleRemoveRequestDto = {
  basketLineEnsembleId: Guid;
  basketUniqueId?: Guid;
};

type BasketLineMultiplyRequestDto = {
  ensembleId: Guid;
  basketUniqueId?: Guid;
  multiplier: number;
};

function none(collection: any[], predicate: (a: any) => boolean) {
  return !collection.some(predicate);
}

export default class BasketComponent {
  public readonly cssClassQuantityInput = "input.js-update-basket-line-quantity-on-change";
  public readonly cssClassMultiplyEnsemble = "input.js-basket-line-ensemble-multiplier";
  public readonly quantitySanitizer = new QuantitySanitizer();
  public readonly elementSelector: string;

  // @ts-expect-error TS6133
  protected getReloadQueryString(basketUniqueId: Guid): string | undefined {
    return undefined;
  }

  constructor(elementSelector: string) {
    this.elementSelector = elementSelector;

    let changeLineQuantity = (element: HTMLElement) => this.changeBasketLineQuantity(element);
    let debouncedChangeLineQuantity = debounce(changeLineQuantity, 1000);

    let multiplyEnsemble = (element: HTMLElement) => this.multiplyEnsemble(element);
    let debouncedMultiplyEnsemble = debounce(multiplyEnsemble, 1000);

    $(elementSelector).on("quantity-changed", this.cssClassQuantityInput, (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      debouncedChangeLineQuantity(e.currentTarget);
    });

    $(elementSelector).on("change input", this.cssClassMultiplyEnsemble, (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      debouncedMultiplyEnsemble(e.currentTarget);
    });

    $(elementSelector).on("click", ".btn.js-remove-basket-line", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.removeBasketLineGroup(e.currentTarget);
    });

    $(elementSelector).on("click", ".btn.js-remove-basket-line-ensemble", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.removeBasketLineEnsemble(e.currentTarget);
    });

    $(document).on("basketLine:conflictResolved", (_e, pid, bid) => {
      this.handleBasketLineConflictResolved(pid, bid);
    });

    $(elementSelector).on("click", ".js-fix-with-change-quantity", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      const $btn = $(e.currentTarget);
      const lineGuid = $btn.data("lineGuid");
      const basketUniqueId = $btn.data("bid");
      const quantity = parseInt($btn.data("quantity"));
      const request: BasketUpdateLineRequestDto = {
        quantity: quantity,
        basketUniqueId: basketUniqueId,
        forceAutoFix: true,
        lineGuid: lineGuid,
      };

      this.changeBasketLineQuantity(undefined, request);
    });

    $(elementSelector).on("click", ".js-scroll-to-basket-line", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      BasketComponent.scrollToBasketLine($(e.currentTarget).data("lineGuid"));
    });
  }

  private handleBasketLineConflictResolved(_pid: string, bid: Guid) {
    // for now, for consistency: always refresh
    this.refreshPage(bid);

    // if ($(".js-alert--unavailable-products").length === 0) return;
    // const $alert = $(".js-alert--unavailable-products").filter(`[data-bid="${bid}"]`);
    // if ($alert.length === 0) return;
    // $alert.children(`.js-basket-line[data-pid="${pid}"]`).remove();
    // if ($alert.children(".js-basket-line").length === 0) {
    //   this.refreshPage(bid);
    // }
  }

  /**
   * Refresh page on focus on a specific basket template (the one matching parameter bid)
   * @param bid Basket unique id (GUID)
   */
  private refreshPage(bid: string) {
    const qs = this.getReloadQueryString(bid);

    if (!qs) {
      location.reload();
    } else {
      window.location.href = window.location.pathname + "?" + qs;
    }
  }

  protected multiplyEnsemble(element: HTMLElement) {
    const $input = $(element);
    const ensembleId = $input.data("ensembleId");
    const basketUniqueId = $input.data("bid");
    const multiplier = parseInt($input.val()?.toString() ?? "0");
    const request: BasketLineMultiplyRequestDto = {
      ensembleId: ensembleId,
      basketUniqueId: basketUniqueId,
      multiplier: multiplier,
    };

    $input.prop("readonly", true);

    const posting = axios.post("/basket/multiply-ensemble", request);

    posting.then((response: any) => {
      const data = response.data as BasketLineQuantityUpdateResponse;
      if (isUpdatedBasketResponse(data)) {
        this.processChangeLineQuantityResponse(data, $input);
      }
    });

    posting.catch(axiosX.defaultCatch);

    posting.catch((_error) => {
      $input.val($input.data("prevQuantity"));
    });

    posting.finally(() => {
      $input.prop("readonly", false);
    });

    return posting;
  }

  protected changeBasketLineQuantity(el?: HTMLElement, sendData?: BasketUpdateLineRequestDto) {
    const self = this;

    const $input = !!el ? $(el) : $(this.cssClassQuantityInput).filter(`[data-line-guid="${sendData?.lineGuid}"]`);
    const quantityValue = $input.val() as string;
    const quantityIntValue = parseInt(quantityValue);

    if (isNaN(quantityIntValue)) {
      // $input.val($input.data("prevQuantity"));
      return Promise.reject();
    }

    const min = parseInt($input.prop("min")); // MOQ
    const step = parseInt($input.prop("step")); // IOQ

    const lineGuid = $input.data("lineGuid");
    const quantity = this.quantitySanitizer.sanitize(quantityIntValue, min, step);
    // only necessary for basket template; for shopping basket, server will get basket from cookie :
    const basketUniqueId = $input.data("bid");
    const forceAutoFix = false;

    if (typeof lineGuid === "undefined") {
      return Promise.reject();
    }

    sendData ??= {
      lineGuid: lineGuid,
      quantity: quantity,
      basketUniqueId: basketUniqueId,
      forceAutoFix: forceAutoFix,
    };

    $input.prop("readonly", true);

    const posting = axios.post("/basket/update", sendData);

    posting.then((response: any) => {
      const data = response.data as BasketLineQuantityUpdateResponse;

      if (isUpdatedBasketResponse(data)) {
        this.processChangeLineQuantityResponse(data, $input);
      }

      if (isInsufficientStockDetectedDtoOfTypeBasketUpdateLineRequestDto(data)) {
        const createAddRequest = (
          encryptedProductId: string | undefined,
          quantity: number,
          basketUniqueId: Guid,
          dataLayerProduct: any,
        ) => {
          return {
            encryptedProductId: encryptedProductId,
            quantity: quantity,
            dataLayerProduct: dataLayerProduct,
            basketUniqueId: basketUniqueId,
          };
        };

        const createUpdateRequest = (
          // @ts-expect-error TS6133
          encryptedProductId: string | undefined,
          quantity: number,
          dataLayerProduct: any,
          basketUniqueId: Guid,
          lineGuid?: Guid,
        ) => {
          return {
            quantity: quantity,
            dataLayerProduct: dataLayerProduct,
            basketUniqueId: basketUniqueId,
            forceAutoFix: forceAutoFix,
            lineGuid: lineGuid ?? "",
          };
        };

        function sendReplacementRequest(request: BasketAddRequestDto) {
          const posting = AddToBasketComponent.addToBasket(request);

          posting.then((response: any) => {
            const data: BasketAddResponseDto = response.data;

            if (isAddedToBasketDto(data)) {
              const $input = $(self.cssClassQuantityInput).filter(`[data-line-guid="${data.lineGuid}"]`);
              $input.val(data.quantity);

              const $line = $input.parents(".js-basket-line");
              $line.find(".js-total-line-amount").text(data.totalLineAmountFormatted);
            }
          });

          return posting;
        }

        function sendRemoveOriginalRequest(request: BasketUpdateLineRequestDto) {
          const $el = !!el ? $(el) : $(`[data-line-group-guid="${request.lineGuid}"]`);
          const $removeBtn = $el.parents(".js-basket-line").find(".btn.js-remove-basket-line");
          const removeBtn = $removeBtn.get()[0];
          return self.removeBasketLineGroup(removeBtn);
        }

        const openInsufficientStockModal = OpenInsufficientStockModalFactory.create<BasketUpdateLineRequestDto>(
          (data: BasketUpdateLineRequestDto) => this.changeBasketLineQuantity(undefined, data),
          sendReplacementRequest,
          sendRemoveOriginalRequest,
          createUpdateRequest,
          createAddRequest,
          () => {
            const $input = $(self.cssClassQuantityInput).filter(`[data-line-guid="${data.originalRequest.lineGuid}"]`);
            $input.val($input.data("prevQuantity"));
          },
        );

        openInsufficientStockModal(data);
      }
    });

    posting.catch(axiosX.defaultCatch);

    posting.catch((_error) => {
      $input.val($input.data("prevQuantity"));
    });

    posting.finally(() => {
      $input.prop("readonly", false);
    });

    return posting;
  }

  private processChangeLineQuantityResponse(data: UpdatedBasketResponse, $input: JQuery<HTMLElement>): void {
    const basketLineGuid = $input.data("lineGuid");
    const invalidProducts = data.updatedBasket.validationResult.invalidProducts;
    const lineIsValid = none(invalidProducts, (ip: InvalidProduct) => ip.basketLineGuid == basketLineGuid);

    if ($input.closest(".js-basket-line").hasClass("js-basket-line--warning") === lineIsValid) {
      // reload page if either
      // a) warning is shown for this line and no warnings are detected after update ==> all problems resolved, or
      // b) no warning is shown for this line and a warning is detected after update ==> new problem triggered
      // cannot access Continue button from this component, so reloading page
      this.refreshPage(data.updatedBasket.basketUniqueId);
    }

    const $component = $(this.elementSelector);

    data.updatedBasket.basketLines.forEach((basketLine) => {
      $component
        .find(this.cssClassQuantityInput)
        .filter(`[data-line-guid="${basketLine.basketLineGuid}"]`)
        .val(basketLine.quantity)
        .data("prevQuantity", basketLine.quantity);

      const $line = $component.find(".js-basket-line").filter(`#${basketLine.basketLineGuid}`);
      $line.find(".js-unit-price").text(`${basketLine.unitSalesPriceFormatted} / ${basketLine.unitOfMeasure.value}`);
      $line.find(".js-total-line-amount").text(basketLine.totalLineAmountFormatted);
    });
  }

  protected removeBasketLineGroup(el: HTMLElement) {
    const $this = $(el);
    const basketLineGroupGuid = $this.data("lineGroupGuid");
    const basketUniqueId = $this.data("bid");

    if (typeof basketLineGroupGuid === "undefined") {
      return Promise.reject();
    }

    const $lines = $(`.js-basket-line[data-line-group-guid="${basketLineGroupGuid}"]`);
    $lines.find("input").prop("readonly", true);
    $lines.find(".btn").prop("disabled", true);

    const request: BasketLineRemoveRequestDto = {
      basketLineGroupGuid: basketLineGroupGuid,
      basketUniqueId: basketUniqueId,
    };

    const posting = axios.post("/basket/remove-line-group", request);

    posting.then((response: any) => {
      $lines.remove();
      $(`#${basketLineGroupGuid}`).remove(); // in case removal triggered by unavailable product

      const data = response.data as BasketLineQuantityUpdateResponse;

      if (
        isUpdatedBasketResponse(data) &&
        (BasketComponent.hasChangesFromShownAlerts(data) || !data.updatedBasket.basketLines.length)
      ) {
        this.refreshPage(data.updatedBasket.basketUniqueId);
      }
    });

    posting.catch(axiosX.defaultCatch);

    posting.finally(() => {
      $lines.find("input").prop("readonly", false);
      $lines.find(".btn").prop("disabled", false);
    });

    return posting;
  }

  private static hasChangesFromShownAlerts(data: UpdatedBasketResponse): boolean {
    const invalidProductsFromResponse = data.updatedBasket.validationResult.invalidProducts.sort((a, b) =>
      a.basketLineGuid.localeCompare(b.basketLineGuid),
    );

    const invalidProductsShownOnPage = $(".js-alert--unavailable-products")
      .find(".js-basket-line")
      .map((_i, el) => {
        const $el = $(el);
        const lineGuid = $el.data("lineGuid") as Guid;
        const lineGroupGuid = $el.data("lineGroupGuid") as Guid;
        const reason = $el.data("reason") as number;
        const result: InvalidProduct = {
          basketLineGroupGuid: lineGuid,
          basketLineGuid: lineGroupGuid,
          reason: reason,
        };

        return result;
      })
      .toArray()
      .sort((a, b) => a.basketLineGuid.localeCompare(b.basketLineGuid));

    if (invalidProductsFromResponse.length !== invalidProductsShownOnPage.length) {
      return true;
    }

    for (let i = 0; i < invalidProductsFromResponse.length; i++) {
      const fromResponse = invalidProductsFromResponse[i];
      const fromPage = invalidProductsShownOnPage[i];

      if (fromResponse.basketLineGuid !== fromPage.basketLineGuid) {
        return true;
      }
      if (fromResponse.reason !== fromPage.reason) {
        return true;
      }
    }

    return false;
  }

  protected removeBasketLineEnsemble(el: HTMLElement) {
    const $this = $(el);
    const ensembleId = $this.data("ensembleId");
    const basketUniqueId = $this.data("bid");

    if (typeof ensembleId === "undefined") {
      return Promise.reject();
    }

    const $lines = $(`[data-ensemble-id="${ensembleId}"]`);
    $lines.find("input").prop("readonly", true);
    $lines.find(".btn").prop("disabled", true);

    const request: BasketLineEnsembleRemoveRequestDto = {
      basketLineEnsembleId: ensembleId,
      basketUniqueId: basketUniqueId,
    };

    const posting = axios.post("/basket/remove-ensemble", request);

    posting.then((_response: any) => {
      $lines.remove();
      $(`#${ensembleId}`).remove(); // in case removal triggered by unavailable product
    });

    posting.catch(axiosX.defaultCatch);

    posting.finally(() => {
      $lines.find("input").prop("readonly", false);
      $lines.find(".btn").prop("disabled", false);
    });

    return posting;
  }

  private static scrollToBasketLine(lineGroupGuid: string) {
    const $line = $(`#${lineGroupGuid}`);
    if ($line.length === 0) return;

    $("html").animate(
      {
        scrollTop: $line.offset()?.top,
      },
      {
        duration: 1000,
      },
    );
  }
}
