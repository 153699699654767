import $ from "jquery";
import { Modal } from "bootstrap";

export default class StyleGuide {
  static init() {
    $(".bs-component").each((_idx, el) => {
      const $button = $(
        "<button class='source-button btn btn-primary btn-xs' role='button' tabindex='0'>&lt; &gt;</button>",
      );

      $(el).append($button);
    });

    $("body").on("click", ".source-button", (e) => {
      e.preventDefault();

      let html = $(e.target).parent().html();
      html = this.cleanSource(html);
      $("#source-modal pre").text(html);

      const sourceModal = Modal.getOrCreateInstance($("#source-modal")[0]);
      sourceModal.show();
    });
  }

  private static cleanSource(html: string) {
    html = html
      .replace(/×/g, "&times;")
      .replace(/«/g, "&laquo;")
      .replace(/»/g, "&raquo;")
      .replace(/←/g, "&larr;")
      .replace(/→/g, "&rarr;");

    let lines = html.split(/\n/);

    lines.shift();
    lines.splice(-1, 1);

    const indentSize = lines[0].length - lines[0].trim().length;
    const re = new RegExp(` {${indentSize}}`);

    lines = lines.map((line: string) => {
      if (line.match(re)) {
        line = line.substring(indentSize);
      }

      return line;
    });

    return lines.join("\n");
  }
}
