import axios from "axios";
import $ from "jquery";

import * as axiosX from "./http/axios-extensions";

$("body").on("click", ".js-download-product-document", (e) => {
  const $this = $(e.currentTarget);
  const url = $this.attr("href");
  const productId = $this.data("productId");
  const body = { productId, url };
  axios.post(`/product-tracking/download-product-document`, body).catch(axiosX.defaultCatch);
});
