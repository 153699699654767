import $ from "jquery";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import type { SwiperOptions } from "swiper/types";

export default class CustomSwiper {
  static init() {
    let bulletSwiperList = document.querySelectorAll(".js-swiper");
    for (let i = 0; i < bulletSwiperList.length; i++) {
      const el = bulletSwiperList[i] as HTMLElement;
      const $el = $(el);

      const usePagination = Number.parseInt($el.data("escPagination")) == 1;
      const useNavigation = Number.parseInt($el.data("escNavigation")) == 1;

      // display just before initializing the component, to prevent non-swiper elements
      $el.toggleClass("d-none", false);

      const modules = [];

      const options: SwiperOptions = {
        direction: "horizontal",
        watchOverflow: true,
      };

      if (usePagination) {
        modules.push(Pagination);
        options.pagination = {
          el: ".js-swiper-pagination",
          clickable: true,
        };
      }

      if (useNavigation) {
        modules.push(Navigation);
        options.navigation = {
          prevEl: ".js-swiper-button-prev",
          nextEl: ".js-swiper-button-next",
        };
      }

      options.modules = modules;

      CustomSwiper.setSwiperBreakpoints($el, options);
      CustomSwiper.setSwiperSlides($el, options);
      console.log(options);
      new Swiper(el, options);
    }
  }

  private static setSwiperBreakpoints($el: JQuery<HTMLElement>, options: SwiperOptions): any {
    const setBreakpoints = $el.data("esc-swiper-breakpoints");

    if (!setBreakpoints) {
      return;
    }

    const columns = $el.data("esc-swiper-breakpoints-col") as number;

    if (columns === 4) {
      options.breakpoints = {
        0: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
      };

      return;
    }

    if (columns === 3) {
      options.breakpoints = {
        0: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      };

      return;
    }

    if (columns === 2) {
      options.breakpoints = {
        0: {
          slidesPerView: 1,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
      };

      return;
    }

    options.breakpoints = {
      0: {
        slidesPerView: 1,
        spaceBetween: 24,
      },
    };
  }

  private static setSwiperSlides($el: JQuery<HTMLElement>, options: SwiperOptions): any {
    const setSlides = $el.data("esc-swiper-slides");

    if (!setSlides) {
      return;
    }

    options.slidesPerView = $el.data("esc-swiper-slides-col") as number;
    options.spaceBetween = 24;
  }
}
