import $$ from "../utils/double-dollar";
import { Tooltip } from "bootstrap";

export default class ProductQuantityPicker {
  private readonly quantityElement: HTMLInputElement | null;
  private tooltip: Tooltip | null = null;
  private allowFormSubmit = false;

  constructor(private el: Element) {
    this.quantityElement = this.el.querySelector(".js-quantity") as HTMLInputElement;

    const decrementElement = this.el.querySelector(".js-quantity-decrement");
    decrementElement?.addEventListener("click", this.decrement.bind(this));

    const incrementElement = this.el.querySelector(".js-quantity-increment");
    incrementElement?.addEventListener("click", this.increment.bind(this));

    this.quantityElement?.addEventListener("change", this.setNewQuantity.bind(this));

    this.quantityElement?.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        this.setNewQuantity();

        if (!this.allowFormSubmit) {
          // do not submit form on enter
          e.preventDefault();
        }
      }
    });
  }

  static init(element: Element | null = null) {
    $$(".js-product-quantity-picker", (el) => new ProductQuantityPicker(el), element);
  }

  public setNewQuantity() {
    if (!this.quantityElement) return;

    const newValue = this.quantityElement.valueAsNumber;

    if (newValue === undefined || newValue === null || Number.isNaN(newValue)) {
      this.quantityElement.valueAsNumber = this.quantity;
    } else {
      this.quantity = newValue;
    }
  }

  public decrement(): void {
    this.quantity = this.quantity - this.ioq;
  }

  public increment(): void {
    this.quantity = this.quantity + this.ioq;
  }

  public get quantity(): number {
    const val: string = this.quantityElement?.getAttribute("value") || "0";
    const qty: number = parseInt(val);
    return Number.isNaN(qty) ? 0 : qty;
  }

  public set quantity(value: number) {
    const currentQuantity = this.quantity;
    this.tooltip?.hide();
    this.allowFormSubmit = true;

    if (value < this.moq) {
      this.allowFormSubmit = false;
      this.raiseHasFeedbackEvent(this.feedbackMoq());
      value = this.moq;
    } else {
      if (value % this.ioq !== 0) {
        this.allowFormSubmit = false;
        this.raiseHasFeedbackEvent(this.feedbackIoq());
      }
      value = Math.ceil(value / this.ioq) * this.ioq;
    }

    if (this.quantityElement !== null) {
      this.quantityElement.value = value.toString();
      this.quantityElement.setAttribute("value", value.toString());
    }

    if (currentQuantity !== value) {
      this.raiseQuantityChangedEvent();
    }
  }

  public get moq(): number {
    const val: string = this.quantityElement?.getAttribute("min") || "0";
    return Math.max(1, parseInt(val));
  }

  public get ioq(): number {
    const val: string = this.quantityElement?.getAttribute("step") || "1";
    return Math.max(1, parseInt(val));
  }

  private showFeedbackTooltip() {
    return this.el.hasAttribute("data-show-feedback-tooltip");
  }

  private feedbackIoq() {
    return this.quantityElement?.dataset?.valStep ?? `Quantity must be a multiple of ${this.ioq}`;
  }

  private feedbackMoq() {
    return this.quantityElement?.dataset?.valNumberrange ?? `Minimum order quantity is ${this.moq}`;
  }

  private raiseQuantityChangedEvent() {
    const event = new CustomEvent("quantity-changed", {
      bubbles: true,
      detail: {
        quantity: this.quantity,
      },
    });

    this.quantityElement?.dispatchEvent(event);
  }

  private raiseHasFeedbackEvent(message: string) {
    const event = new CustomEvent("has-feedback", {
      bubbles: true,
      detail: {
        message: message,
      },
    });

    this.quantityElement?.dispatchEvent(event);

    if (this.showFeedbackTooltip() && !!this.quantityElement) {
      this.tooltip = new Tooltip(this.quantityElement, { title: message, trigger: "manual" });
      this.tooltip.show();
      setTimeout(() => this.tooltip?.hide(), 2000);
    }
  }
}

ProductQuantityPicker.init();
