import $ from "jquery";

import type { Guid, BasketAddRequestDto, InsufficientStockDetectedDto, IBasketLineRequest } from "./types";
import { Modal } from "bootstrap";

const insufficientStockModalSelector = "#insufficient-stock-modal";

export default class OpenInsufficientStockModalFactory {
  public static create<TOriginalRequest extends IBasketLineRequest>(
    sendOriginalRequest: (data: TOriginalRequest) => Promise<any>,
    sendReplacementRequest: (data: BasketAddRequestDto) => Promise<any>,
    sendRemoveOriginalRequest: (data: TOriginalRequest) => Promise<any>,
    createOriginalRequest: (
      encryptedProductId: string | undefined,
      quantity: number,
      basketUniqueId: Guid,
      dataLayerProduct: any,
      lineGuid?: Guid,
    ) => TOriginalRequest,
    createReplacementRequest: (
      encryptedProductId: string | undefined,
      quantity: number,
      basketUniqueId: Guid,
      dataLayerProduct: any,
    ) => BasketAddRequestDto,
    onCancelAction?: () => void,
  ) {
    return function openInsufficientStockModal(data: InsufficientStockDetectedDto<TOriginalRequest>) {
      const $modal = $(insufficientStockModalSelector);
      const template = $modal.find(".js-template").html();

      let html = template
        .replace(
          /{originalProductName}/g,
          `<span class="nt-insufficient-stock-product-name--original">${data.originalProductName}</span>`,
        )
        .replace(
          /{replacementProductName}/g,
          `<a href="${data.replacementProduct?.uri}" target="_blank" class="nt-insufficient-stock-product-name--replacement">${data.replacementProduct?.name}</a>`,
        )
        .replace(/{originalProductStockcount}/g, data.originalRemainingProductStockCount.toString());

      $(".js-replaced").html(html);

      if (!!data.replacementProduct) {
        // replacement suggested
        $(".js-show-only-when-replacement-suggested").removeClass("d-none");
        $(".js-show-only-when-no-replacement-suggested").addClass("d-none");
        $(".js-show-only-when-stock-depleted-and-no-replacement").addClass("d-none");

        if (data.originalRemainingProductStockCount === 0) {
          // stock of original depleted
          $(".js-show-only-when-stock-available").addClass("d-none");
          $(".js-show-only-when-stock-available-and-replacement-suggested").addClass("d-none");
        } else {
          // stock of original available
          $(".js-show-only-when-stock-available").removeClass("d-none");
          $(".js-show-only-when-stock-available-and-replacement-suggested").removeClass("d-none");
        }
      } else {
        // no replacement product
        $(".js-show-only-when-replacement-suggested").addClass("d-none");
        $(".js-show-only-when-no-replacement-suggested").removeClass("d-none");
        $(".js-show-only-when-stock-available-and-replacement-suggested").addClass("d-none");

        if (data.originalRemainingProductStockCount === 0) {
          // stock of original depleted
          $(".js-show-only-when-stock-depleted-and-no-replacement").removeClass("d-none");
          $(".js-show-only-when-stock-available").addClass("d-none");
        } else {
          // stock of original available
          $(".js-show-only-when-stock-depleted-and-no-replacement").addClass("d-none");
          $(".js-show-only-when-stock-available").addClass("d-none");
        }
      }

      const modal = Modal.getOrCreateInstance($modal[0]);

      function modalClickHandlerWrapper(e: JQuery.ClickEvent, action: () => void) {
        e.preventDefault();
        e.stopImmediatePropagation();

        action();

        $modal.off("click");
        modal.hide();

        $(".js-confirm").prop("disabled", true);
      }

      function triggerChangeEvent(originalRequest: TOriginalRequest) {
        const pid = originalRequest.encryptedProductId;
        const bid = originalRequest.basketUniqueId;
        $(document).trigger("basketLine:conflictResolved", [pid, bid]);
      }

      function getConfirmAction(radioValue?: string) {
        let confirmAction: () => void;

        switch (radioValue) {
          case "order-remaining-stock-of-original":
            confirmAction = () => {
              const sendData: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                data.originalRemainingProductStockCount,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );

              sendOriginalRequest(sendData).then(() => triggerChangeEvent(data.originalRequest));
            };

            break;
          case "order-replacement":
            confirmAction = () => {
              const originalOrder: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                0,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );

              const replacementOrder: BasketAddRequestDto = createReplacementRequest(
                data.replacementProduct?.encryptedProductId,
                data.originalRequest.quantity,
                data.originalRequest.basketUniqueId,
                data.replacementProduct,
              );

              sendReplacementRequest(replacementOrder)
                .then(() => sendRemoveOriginalRequest(originalOrder))
                .then(() => triggerChangeEvent(data.originalRequest));
            };

            break;
          case "order-stock-of-original-and-supplement-with-replacement":
            confirmAction = () => {
              const originalOrder: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                data.originalRemainingProductStockCount,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );

              const replacementOrder: BasketAddRequestDto = createReplacementRequest(
                data.replacementProduct?.encryptedProductId,
                data.originalRequest.quantity - data.originalRemainingProductStockCount,
                data.originalRequest.basketUniqueId,
                data.replacementProduct,
              );

              // our basket service is not 'thread-safe'/can't handle concurrent requests
              sendReplacementRequest(replacementOrder)
                .then(() => sendOriginalRequest(originalOrder))
                .then(() => triggerChangeEvent(data.originalRequest));
            };

            break;
          case "remove-original":
            confirmAction = () => {
              const originalOrder: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                0,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );

              sendRemoveOriginalRequest(originalOrder).then(() => triggerChangeEvent(data.originalRequest));
            };
            break;
          default:
            console.warn(`No action for selected option: ${radioValue}`);
            confirmAction = () => {};
            break;
        }

        return confirmAction;
      }

      $modal.on("click", ".js-confirm", (e) => {
        modalClickHandlerWrapper(e, () => {
          const radioValue = $(`input[type="radio"][name="conflict-resolution-option"]:checked`).val()?.toString();
          let confirmAction: () => void = getConfirmAction(radioValue);
          confirmAction();
        });
      });

      $modal.on("click", ".js-cancel", (e) => {
        modalClickHandlerWrapper(e, () => {
          if (!!onCancelAction) {
            onCancelAction();
          }
        });
      });

      $modal.on("click", `input[type="radio"][name="conflict-resolution-option"]`, () => {
        $(".js-confirm").prop("disabled", false);
      });

      modal.show();
    };
  }
}
