import axios, { AxiosResponse } from "axios";
import $ from "jquery";
import debounce from "lodash-es/debounce";

import * as axiosX from "./http/axios-extensions";

import "./components/bs5-autocomplete-with-dividers.ts";

import type { AutocompleteWithDividers, DividedAutocompleteItem } from "./components/bs5-autocomplete-with-dividers.ts";

interface SearchSuggestion {
  /**
   * Text to show in the dropdown.
   */
  text: string;
  /**
   * URI to navigate to when the option is selected frmo the dropdown.
   */
  uri: string;
  isFromHistory: boolean;
}

export default class SearchAutoComplete {
  private $searchInputSelector: JQuery<HTMLElement>;

  public constructor(searchInputSelector: string) {
    this.$searchInputSelector = $(searchInputSelector);
    let debounced = debounce(async (element: HTMLElement) => await this.getSearchSuggestions(element), 500);
    this.$searchInputSelector.on("input focus", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      debounced(e.target);
    });
  }

  private async getSearchSuggestions(el: HTMLElement) {
    const instance = this;
    const $el = $(el);
    const searchTerm = encodeURIComponent(("" + $el.val()).trim());

    let length = `${searchTerm}`.length;
    if (length != 0 && length < 3) {
      return;
    }

    try {
      // TODO should add memoize
      const data = await axios.get(`/search-suggestions?query=${searchTerm}`);
      processSearchSuggestions(data);
    } catch (e) {
      axiosX.defaultCatch(e);
    }

    function processSearchSuggestions(response: AxiosResponse<any>) {
      let dictionary: { [key: string]: DividedAutocompleteItem[] } = {};
      const data = response.data;
      const translations = data.translations;
      // 1st TEXT suggestions
      dictionary[translations.textSuggestions] = [];
      const textSuggestions = data.textSuggestions as SearchSuggestion[];
      for (const textSuggestion of textSuggestions) {
        let iconClass = textSuggestion.isFromHistory ? "fas fa-history fa-xs text-medium" : null;
        dictionary[translations.textSuggestions].push({
          label: textSuggestion.text,
          value: "",
          iconClass: iconClass,
        });
      }
      // 2nd BRAND suggestions
      dictionary[translations.brandSuggestions] = [];
      const brandSuggestions = data.brandSuggestions as any[];
      for (const brand of brandSuggestions) {
        dictionary[translations.brandSuggestions].push({ label: brand.text, value: brand.uri, iconClass: null });
      }

      let autocomplete = <AutocompleteWithDividers>$el;
      autocomplete.autocompleteWithDividers({
        source: dictionary,
        onSelectItem: (value, label, _element) => {
          if (value) {
            location.href = `${window.location.origin}/${value}`;
            return;
          }
          setSearchTextAndSubmit(label);
        },
        highlightClass: "text-warning",
        threshold: 0,
        maximumItems: 10,
      });
      $el.trigger("click"); // trigger the dropdown to open
    }

    /**
     * Instead of using URI, set search text on form and submit form
     * such that all the search form logic can be followed
     * (i.e. get all brands that match a search term, even if a brand
     * has already been filtered on).
     * @param searchText
     */
    function setSearchTextAndSubmit(searchText: string) {
      instance.$searchInputSelector.val(searchText);
      instance.$searchInputSelector.parents("form").trigger("submit");
    }
  }
}
