import $ from "jquery";

export default function smoothScrollTo(anchor: string, offset?: number) {
  if (anchor === "#top") {
    const properties = { scrollTop: 0 };
    $("html,body").animate(properties, 300);
    return true;
  }

  let $target = $(anchor);
  $target = ($target.length && $target) || $(`[name='${anchor.slice(1)}']`);
  if ($target.length) {
    // if we don't add extra pixel it will scroll too far because of the sticky header that overlaps the viewport
    const offsetFromTop = ($target.offset()?.top ?? 0) - (offset || 15);
    const properties1 = { scrollTop: offsetFromTop };
    $("html,body").animate(properties1, 800);
    return true;
  }

  return false;
}
