/**
 * AKA MultipleOfSoqSuggester.
 */
export default class PackagePromoter {
  moq: number;
  ioq: number;
  soq: number;

  constructor(moq: number, ioq: number, soq: number) {
    this.moq = moq;
    this.ioq = ioq;
    this.soq = soq;
  }

  public suggestedQuantity(quantity: number): number {
    if (quantity < 0.8 * this.soq || quantity % this.soq === 0) {
      return quantity;
    }

    let suggestedQuantity = (Math.floor(quantity / this.soq) + 1) * this.soq;

    if (quantity % this.ioq !== 0) {
      suggestedQuantity = (Math.floor(quantity / this.ioq) + 1) * this.ioq;
    }

    return suggestedQuantity;
  }
}
