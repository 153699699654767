import $ from "jquery";

$(".js-onclick-open-configurator").on("click", (e) => {
  e.preventDefault();
  e.stopImmediatePropagation();

  const $el = $(e.currentTarget);
  const configuratorFormId = $el.data("configurator-form-id");

  const $form = $(`#${configuratorFormId}`);
  $form.trigger("submit");
});
