import * as HttpProblemDetails from "./http-problem-details";
import "toastr";

export function defaultCatch(error: any) {
  // console.error("axios-extensions.ts: defaultCatch(error) with error =", error);
  if (error.response) {
    const details = HttpProblemDetails.asHttpProblemDetails(
      error.response.data,
      error.response.data,
      error.response.status,
    );

    if (details.status < 500) {
      toastr.warning(details.detail, details.title);
    } else {
      toastr.error(details.detail, details.title);
    }
  } else if (error.request) {
    toastr.error(`No response received for request ${error.request}`, "ERROR");
  } else {
    toastr.error(`Fatal request ${error}`, "ERROR");
  }
}
