import $ from "jquery";
import axios from "axios";
import * as axiosX from "./http/axios-extensions";
import $$ from "./utils/double-dollar";
import "toastr";

import type { QuickSearchProductDto } from "./baskets/types";
import AddToBasketComponent from "./baskets/add-to-basket";
import Forms from "./utils/forms";
import Multilang from "./utils/multilang.ts";

class ProductQuickEntry {
  $searchTermInput: JQuery<HTMLElement>;
  $quantityInput: JQuery<HTMLElement>;
  $form: JQuery<HTMLElement>;

  private readonly formSelector = ".js-product-quick-entry--form";
  private readonly quantityInputSelector = "input[name='quantity']";
  private readonly modalTriggerSelector = "button.js-quick-search-modal-trigger";

  constructor(element: HTMLElement) {
    this.$searchTermInput = $(element);
    this.$form = $(this.formSelector);
    this.$quantityInput = $(this.quantityInputSelector, this.$form);

    $(document).on("submit", this.formSelector, (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();

      const searchTerm = this.$searchTermInput.val() as string;
      const quantityString = this.$quantityInput.val() as string;
      this.toggleFormState(false);

      if (quantityString === "" || searchTerm === "") {
        this.resetComponent();
        return;
      }

      const encodedSearchTerm = encodeURIComponent(searchTerm);

      axios
        .get(`/quick-search/${encodedSearchTerm}`)
        .then((response) => {
          const product = response.data as QuickSearchProductDto;

          const ioqValidationMessageTemplate = $("#IOQValidationMessageTemplate").val() as string;
          const ioqValidationMessage = ioqValidationMessageTemplate.replace("Step", "0");

          this.$quantityInput.prop("min", product.minimumOrderQuantity);
          this.$quantityInput.prop("step", product.incrementalOrderQuantity);

          this.$quantityInput.data("soq", product.standardOrderQuantity);
          this.$form.data("pid", product.encryptedProductId ?? "");
          this.$form.data("datalayerCartItem", JSON.stringify(product?.dataLayerProduct));

          // jquery validation has to be extended dynamically as the validation adapter does not track changes of the element
          // therefor extending the messages is the only option
          jQuery.extend(jQuery.validator.messages, {
            min: jQuery.validator.format(ioqValidationMessage),
            step: jQuery.validator.format(ioqValidationMessage),
          });

          if (!this.$form.valid()) {
            // if the form is not valid we will not erase the content of the search term input.
            // we do reset the min and step property as the user could want to add a different product
            // which is why the moq and ioq need to be reset
            this.toggleFormState(true);
            Forms.resetSubmitButtons();

            this.$quantityInput.prop("min", 1);
            this.$quantityInput.prop("step", 1);

            return;
          }

          AddToBasketComponent.submitAddToBasketForm(e.target);
          this.resetComponent();
        })
        .catch((error) => {
          if (error.response.status == 404) {
            toastr.error(Multilang.getTranslation("basket.toast.product_not_found", "Het product bestaat niet."));
          } else {
            axiosX.defaultCatch(error);
          }

          this.toggleFormState(true);
          Forms.resetSubmitButtons();

          this.$quantityInput.prop("min", 1);
          this.$quantityInput.prop("step", 1);
        });
    });

    $(document).on("click", this.modalTriggerSelector, (_) => {
      this.$searchTermInput.trigger("focus");
    });
  }

  private resetComponent() {
    this.toggleFormState(true);
    Forms.resetSubmitButtons();
    this.resetForm();
  }

  private resetForm() {
    this.$searchTermInput.val("");
    this.$searchTermInput.trigger("focus");
    this.$quantityInput.val(1);
    this.$quantityInput.prop("min", 1);
    this.$quantityInput.prop("step", 1);
  }

  private toggleFormState(enabled: boolean) {
    this.$searchTermInput.prop("readonly", !enabled);
    this.$quantityInput.prop("readonly", !enabled);
  }
}

$$(".js-product-quick-entry--input", (el) => new ProductQuickEntry(el as HTMLElement));
