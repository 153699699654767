import $$ from "./double-dollar.ts";

class HistoryGoBack {
  constructor(el: HTMLElement) {
    el.addEventListener("click", (e) => {
      if (history.length > 1) {
        e.preventDefault();
        history.back();
      }
    });
  }
}

$$(".js-history-go-back", (el) => new HistoryGoBack(el));
