import $ from "jquery";
import "toastr";

import BasketComponent from "./basket";
import MiniBasket from "./mini-basket";
import type {
  InsufficientStockDetectedDto,
  BasketUpdateLineRequestDto,
  BasketContent,
  ChangeLineQuantityResponse,
} from "./types";

import NatchGtm, { EventNamesSchema } from "natch-gtm4";
import type { AxiosResponse } from "axios";
import Multilang from "../utils/multilang.ts";

type BasketLineQuantityUpdateResponse =
  | ChangeLineQuantityResponse
  | InsufficientStockDetectedDto<BasketUpdateLineRequestDto>;

function isChangeLineQuantityResponse(
  response: BasketLineQuantityUpdateResponse,
): response is ChangeLineQuantityResponse {
  return (response as ChangeLineQuantityResponse).quantity !== undefined;
}

export class ShoppingBasketComponent extends BasketComponent {
  private natchGtm = new NatchGtm(EventNamesSchema.CustomPrefixedWithEEC);

  constructor(elementSelector: string) {
    super(elementSelector);
  }

  protected changeBasketLineQuantity(el?: HTMLElement, sendData?: BasketUpdateLineRequestDto) {
    let promise = super.changeBasketLineQuantity(el, sendData);

    promise.then((response: any) => {
      const data = response.data as BasketLineQuantityUpdateResponse;

      if (isChangeLineQuantityResponse(data)) {
        toastr.success(Multilang.getTranslation("basket.toast.basket_updated", "De winkelwagen werd bijgewerkt."));
        ShoppingBasketComponent.fillInTotals(data.updatedBasket);
        MiniBasket.update();
      }
    });

    return promise;
  }

  protected removeBasketLineGroup(el: HTMLElement) {
    return this.wrapRemoveBasketLines(el, (arg) => super.removeBasketLineGroup(arg));
  }

  protected removeBasketLineEnsemble(el: HTMLElement) {
    return this.wrapRemoveBasketLines(el, (arg) => super.removeBasketLineEnsemble(arg));
  }

  private wrapRemoveBasketLines(el: HTMLElement, removeFunction: (arg0: HTMLElement) => Promise<AxiosResponse<any>>) {
    const self = this;
    const $this = $(el);

    // get GTM datalayer data in a safe way
    const jsonString = $this.parents("[data-datalayer-cart-item]").attr("data-datalayer-cart-item");
    // console.log(jsonString);
    const dataLayerProduct = jsonString ? JSON.parse(jsonString) : null;
    // console.log(dataLayerProduct);

    let promise = removeFunction(el);

    promise.then((response: any) => {
      toastr.success(
        Multilang.getTranslation("basket.toast.product_updated", "Het product werd verwijderd uit de winkelwagen."),
      );

      ShoppingBasketComponent.fillInTotals(response.data.updatedBasket);
      MiniBasket.update();

      if (dataLayerProduct !== null) {
        ////console.log("Calling natchGtm.trackRemoveFromCart", dataLayerProduct);
        self.natchGtm.trackRemoveFromCart(dataLayerProduct);
      }

      const $unavailableProducts = $(".js-alert--unavailable-products");

      if ($unavailableProducts.children(".js-basket-line").length === 0) {
        $unavailableProducts.addClass("d-none");
      }

      $(".js-btn-continue").prop("disabled", !response.data.updatedBasket.canContinue);
    });
    return promise;
  }

  private static fillInTotals(updatedBasket: BasketContent) {
    $(".js-basket-updateable-sub-total").text(updatedBasket.subTotalFormatted);

    for (let productSurchargeTotal of updatedBasket.productSurchargeTotals) {
      let selector = `.js-basket-updateable-surcharge-total[data-product-surcharge-type-id="${productSurchargeTotal.productSurchargeType.id}"]`;
      $(selector).text(productSurchargeTotal.amountFormatted);
    }

    $(".js-basket-updateable-total").text(updatedBasket.totalFormatted);
  }
}

new ShoppingBasketComponent(".js-basket-view.js-basket-view--shopping-basket");
