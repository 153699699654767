import $ from "jquery";

export default class MiniBasket {
  private static _basketId: string | undefined;

  public static update() {
    const miniBasketElement = document.getElementById("mini-basket") ?? document.createElement("div");
    const basketId = this.getBasketId(miniBasketElement);
    const isApprovalBasket = miniBasketElement.dataset?.isApprovalBasket;

    const posting = $.get(`/basket/summary?basketId=${basketId}&isApprovalBasket=${isApprovalBasket}`);

    posting.done((data: any) => {
      this.setBasketId("" + data.basketId);

      const amountElement = document.getElementById("mini-basket__amount");

      if (amountElement) {
        amountElement.innerHTML = data.totalAmountFormatted;
      }

      const lineCountElement = document.getElementById("mini-basket__line-count");

      if (lineCountElement) {
        lineCountElement.innerHTML = data.lineCountFormatted;

        if (data.lineCount > 0) {
          miniBasketElement.classList.remove("disabled");
          lineCountElement.classList.remove("d-none");
        } else {
          miniBasketElement.classList.add("disabled");
          lineCountElement.classList.add("d-none");
        }
      }
    });
  }

  private static getBasketId(miniBasketElement: HTMLElement) {
    if (!!this._basketId) {
      return this._basketId;
    }

    let basketId = miniBasketElement.dataset?.basketId;

    if (basketId === undefined) {
      return "0";
    }

    return (this._basketId = basketId);
  }

  private static setBasketId(basketId: string) {
    this._basketId = basketId;
  }
}
