import axios from "axios";
import * as axiosX from "./http/axios-extensions";

export default class CheckoutContent {
  static init() {
    const form = document.getElementById("handsoft-order-reference-form") as HTMLFormElement;
    if (!form) {
      return;
    }

    form.addEventListener("submit", (e) => {
      // block submit on enter in the field
      e.preventDefault();
      return false;
    });

    const field = form.getElementsByTagName("input")[0];
    field.addEventListener("change", (ev) => {
      const orderReferenceField = ev.target as HTMLInputElement;
      if (!orderReferenceField.checkValidity()) {
        return;
      }

      orderReferenceField.classList.add("esc-ajax-field-loading");

      axios
        .post("/basket/reference", { reference: orderReferenceField.value })
        .then((result) => {
          orderReferenceField.classList.remove("esc-ajax-field-loading");
          console.log(result);
        })
        .catch(axiosX.defaultCatch);
    });
  }
}
