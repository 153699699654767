import $ from "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";

/* this declaration in /clientapp/types/static.d.ts isn't picked up, so putting it here */
declare global {
  interface Window {
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

// need jQuery on the window object for jquery validation
window.jQuery = $;

enum AtsTransferType {
  Pickup = "Pickup",
  Delivery = "Delivery",
  PickupNightLocker = "PickupNightLocker",
}

enum Trigger {
  Initial,
  OnChange,
}

export default class CheckoutDetails {
  static init() {
    const $checkoutStep2 = $(".js-checkout-step2");

    if ($checkoutStep2.length === 0) {
      // only execute on checkout page 2 (Details)
      return;
    }

    // transfer type changed -> show pickup/delivery address
    function setAddressSelectorsVisibility(trigger: Trigger) {
      const $select = $("select#Form_TransferType");

      $(".js-pickup-address-selector, .js-delivery-address-selector").addClass("d-none");

      const transferType = $select.val() as string;
      const elementToShowSelector =
        transferType == AtsTransferType.Delivery ? ".js-delivery-address-selector" : ".js-pickup-address-selector";
      $(elementToShowSelector).removeClass("d-none");

      if (transferType != AtsTransferType.Delivery) {
        const $all = $(".js-pickup-address-selector select");
        $all.addClass("d-none").prop("disabled", true);

        const $toShow = $(`.js-pickup-address-selector select[data-transfertype-id=${$select.val()}]`);

        $toShow.removeClass("d-none").prop("disabled", false);

        if (trigger == Trigger.OnChange) {
          $all.prop("selected", false).val("");
          const newValue = $toShow.find("option").first().prop("selected", true).val();
          console.log(`setting new value ${newValue} on select box`);
        }

        setPickupAddressesVisibility();
      } else {
        setDeliveryAddressesVisibility($("select#Form_DeliveryAddressId"));
      }
    }

    $checkoutStep2.on("change", "select#Form_TransferType", () => {
      setAddressSelectorsVisibility(Trigger.OnChange);
    });

    setAddressSelectorsVisibility(Trigger.Initial);

    // radio changed -> show corresponding date selector
    function setDateSelectorsVisibility() {
      const $checked = $("input[name='Form.ShippingAdviceOption']:checked");

      $(".js-checkout-step2--shipping-advice-date").addClass("d-none");
      $(".field-validation-error").addClass("d-none");

      const $shippingAdvice = $checked.closest(".js-checkout-step2--shipping-advice");
      $shippingAdvice.find(".js-checkout-step2--shipping-advice-date").removeClass("d-none");
      $shippingAdvice.find(".field-validation-error").removeClass("d-none");
    }

    $checkoutStep2.on("change", "input[name='Form.ShippingAdviceOption']", () => {
      setDateSelectorsVisibility();
    });

    setDateSelectorsVisibility();

    // pickup address changed -> show address details
    function setPickupAddressesVisibility() {
      const $select = $("select#Form_TransferSubTypeId");

      // there are two select boxes with the same id 👀
      $select.each((_index, element) => {
        const $element = $(element);
        const val = $element.val();

        if (!!val && $element.prop("disabled") == false) {
          console.log("setPickupAddressesVisibility for option");

          let $address = $(`.js-address-details[data-transfersubtype-id=${val}]`);
          $address.siblings(".js-address-details").addClass("d-none");
          $address.removeClass("d-none");
        }
      });
    }

    $(".js-pickup-address-selector").on("change", "select#Form_TransferSubTypeId", () => {
      setPickupAddressesVisibility();
    });

    // setPickupAddressesVisibility();

    // delivery address changed -> show address details
    function setDeliveryAddressesVisibility($select: JQuery<HTMLElement>) {
      const $address = $(`.js-address-details[data-address-id=${$select.val()}]`);
      $address.siblings(".js-address-details").addClass("d-none");
      $address.removeClass("d-none");
    }

    $(".js-delivery-address-selector").on("change", "select#Form_DeliveryAddressId", (e) => {
      setDeliveryAddressesVisibility($(e.currentTarget));
    });

    setDeliveryAddressesVisibility($("select#Form_DeliveryAddressId"));
  }
}

CheckoutDetails.init();
