import axios from "axios";
import $ from "jquery";

import * as axiosX from "../http/axios-extensions";

type ChangeCustomerProductCodeRequest = {
  productCode: string;
  customerProductCode: string;
  oldCustomerProductCode: string;
};

export class CustomerProductCodeBox {
  private readonly $this: JQuery<HTMLElement>;
  private readonly $parent: JQuery<HTMLElement>;

  constructor(selector: string) {
    this.$this = $(selector);
    this.$parent = this.$this.parent();
    this.$this.on("change", () => {
      this.sendChangeRequest();
    });
    this.$this.on("focus", () => {
      this.clearAllFeedback();
    });
  }

  private sendChangeRequest() {
    const endpoint = this.$this.data("endpoint");
    const productCode = this.$this.data("productCode");
    const customerProductCode = this.$this.val() + "";
    const oldCustomerProductCode = this.$this.data("oldValue");
    const request: ChangeCustomerProductCodeRequest = {
      productCode: productCode,
      customerProductCode: customerProductCode,
      oldCustomerProductCode: oldCustomerProductCode,
    };
    axios
      .post(endpoint, request)
      .then((_response) => {
        this.clearAllFeedback();
        this.$this.addClass("is-valid");
      })
      .catch((error) => {
        this.$this.addClass("is-invalid");
        if (error.response && error.response.status === 422) {
          this.addInvalidFeedback(error.response.data);
          return;
        }
        this.addFailureFeedback();
        axiosX.defaultCatch(error);
      });
  }

  private clearAllFeedback() {
    this.$this.removeClass("is-valid");
    this.$this.removeClass("is-invalid");
    this.$this.siblings().remove();
  }

  private addInvalidFeedback(messages: string[]) {
    const $feedback = $(`<div class="invalid-feedback"></div>`);
    for (const message of messages) {
      $feedback.append(`<span class="d-block">${message}</span>`);
    }
    this.$parent.append($feedback);
  }

  private addFailureFeedback() {
    const $feedback = $(`<div class="invalid-feedback">Sorry, we could not save the information.<br /></div>`);
    const $retry = $(`<a href="javascript:">Retry</a>`);
    $retry.on("click", () => this.sendChangeRequest());
    $feedback.append($retry);
    this.$parent.append($feedback);
  }
}

new CustomerProductCodeBox(".js-change-customer-product-code");
