import $ from "jquery";
import axios from "axios";

import * as axiosX from "./http/axios-extensions";

import debounce from "lodash-es/debounce";
import "./components/bs5-autocomplete-with-dividers.ts";
import type { AutocompleteWithDividers, DividedAutocompleteItem } from "./components/bs5-autocomplete-with-dividers.ts";

type SelectListItem = {
  text: string;
  value: string;
};

export default class Impersonation {
  protected customersDictionary: object = {};

  public constructor() {
    const $el = $(".js-impersonation-select--customer");
    const $customersJson = $("input.js-impersonation-customers");

    if ($customersJson.length === 0) return;
    this.customersDictionary = JSON.parse(($customersJson.val() ?? "").toString());

    let debounced = debounce(async (element: HTMLElement) => await this.showMatchingCustomers(element), 500);
    $el.on("input focus", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      debounced(e.currentTarget);
    });
  }

  private async showMatchingCustomers(el: HTMLElement) {
    const $el = $(el);
    const searchTerm = ("" + $el.val()).trim();

    let length = `${searchTerm}`.length;
    if (length < 1) {
      return;
    }

    const searchTermUpper = searchTerm.toUpperCase();
    const matchingCustomers = Object.fromEntries(
      Object.entries(this.customersDictionary).filter(([key]) => key.toUpperCase().includes(searchTermUpper)),
    );

    this.fillCustomers(matchingCustomers);

    const customerId = $(".js-form-field-person-id").val();
    if (customerId !== "") {
      Impersonation.getPersons((customerId ?? "").toString());
    }
  }

  private static getPersons(customerId: string | number) {
    axios
      .get(`/impersonation/persons?customerId=${customerId}`)
      .then(Impersonation.fillPersons)
      .catch(axiosX.defaultCatch);
  }

  private fillCustomers(matchingCustomers: { [p: string]: string }) {
    const $el = $(".js-impersonation-select--customer");
    let autocomplete = <AutocompleteWithDividers>$el;

    let dictionary: { [key: string]: DividedAutocompleteItem[] } = {};
    dictionary[""] = [];

    for (const [key, value] of Object.entries(matchingCustomers)) {
      dictionary[""].push({ label: key, value: value, iconClass: null });
    }

    autocomplete.autocompleteWithDividers({
      source: dictionary,
      onSelectItem: (item, _, element) => {
        const $element = $(element);

        $(".js-impersonation-select--person").text("").val("");
        Impersonation.getPersons(item);

        const $form = $element.parents("form");
        $form.find(".js-form-field-customer-id").val(item);
      },
      highlightClass: "text-warning",
      threshold: 0,
      maximumItems: 10,
    });

    $el.trigger("click"); // trigger the dropdown to open
  }

  private static fillPersons(response: any) {
    if (response.data === "") {
      return;
    }

    let listItems: SelectListItem[] = response.data as SelectListItem[];
    let personsDictionary: { [p: string]: string } = {};

    listItems.forEach((item) => {
      personsDictionary[item.text] = item.value;
    });

    const $el = $(".js-impersonation-select--person");
    let autocomplete = <AutocompleteWithDividers>$el;

    let dictionary: { [key: string]: DividedAutocompleteItem[] } = {};
    dictionary[""] = [];

    for (const [key, value] of Object.entries(personsDictionary)) {
      dictionary[""].push({ label: key, value: value, iconClass: null });
    }

    autocomplete.autocompleteWithDividers({
      source: dictionary,
      onSelectItem: (item, _, element) => {
        if (!item) return;

        const $element = $(element);
        const $form = $element.parents("form");
        $form.find(".btn").prop("disabled", false);
        $form.find(".js-form-field-person-id").val(item);
      },
      highlightClass: "text-warning",
      threshold: 0,
      maximumItems: 10,
    });
  }
}
