import $ from "jquery";
import axios, { AxiosResponse } from "axios";
import * as axiosX from "../http/axios-extensions";
import "./bs5-autocomplete-with-dividers.ts";
import type { AutocompleteWithDividers } from "./bs5-autocomplete-with-dividers.ts";
import map from "lodash-es/map";
import get from "lodash-es/get";
import { DividedAutocompleteItem } from "./bs5-autocomplete-with-dividers.ts";

export default class Autocomplete {
  private optionTextToValue: { [Key: string]: string } = {};
  private valueToSelectedOptionText: { [Key: string]: string } = {};

  constructor(e: Element) {
    const $e = $(e);
    this.init($e);
  }

  init($e: JQuery<Element>) {
    const uri = $e.data("uri");
    axios
      .get(uri)
      .then((response: AxiosResponse<any>) => this.fillOptions(response, $e))
      .catch(axiosX.defaultCatch);
  }

  fillOptions(response: any, $e: JQuery<Element>) {
    const valuePath = $e.data("valuePath");
    const optionTextPath = $e.data("optionTextPath");
    const selectedOptionTextPath = $e.data("selectedOptionTextPath");
    let listItems = map(response.data, (item: any) => {
      return {
        text: get(item, optionTextPath),
        value: get(item, valuePath),
        textIfSelected: get(item, selectedOptionTextPath),
      };
    });
    listItems.forEach((item: any) => {
      this.optionTextToValue[item.text] = item.value;
      this.valueToSelectedOptionText[item.value] = item.textIfSelected;
    });
    const source: DividedAutocompleteItem[] = map(listItems, (item: any) => ({
      label: item.text,
      value: item.value,
      iconClass: null,
    }));
    let autocomplete = <AutocompleteWithDividers>$e;
    autocomplete.autocompleteWithDividers({
      source: { "": source },
      onSelectItem: (_: string, __: string, element: HTMLElement) => {
        const $element = $(element);
        const optionText = $element.val()?.toString().trimEnd() ?? "";
        const value = this.optionTextToValue[optionText];
        $element.val(this.valueToSelectedOptionText[value]);
      },
      highlightClass: "text-warning",
      threshold: 0,
      maximumItems: 10,
    });
  }
}
$(function () {
  $(".js-autocomplete").each((_: number, e: Element) => {
    new Autocomplete(e);
  });
});
