// TODO try async/await
import axios from "axios";
import $ from "jquery";
import * as axiosX from "./http/axios-extensions";

type ProductPriceResultDto = {
  productId: number;
  price?: string;
};

export default class ProductPricing {
  static init() {
    const elements = document.getElementsByClassName("js-product-pricing");

    if (elements.length === 0) {
      return;
    }

    const elementsArray = Array.from(elements);
    const productIdList = elementsArray.map((el) => {
      return parseInt(el.getAttribute("data-productid") || "0");
    });
    ////.filter((n) => n > 500000); //-- add this to test "missing" prices

    this.getProductPrices(productIdList, (httpStatusCode, results) => {
      elementsArray.forEach((el) => {
        // set pricing layout
        const el$ = $(el);
        el$.removeClass("esc-product-pricing--loading");

        if (httpStatusCode !== 200) {
          // e.g. 204 No Content
          el$.addClass("esc-product-pricing--failed");

          // disable add to basket :
          const formObjects$ = el$.closest(".js-product").find("button, input");

          if (formObjects$ && formObjects$.length) {
            formObjects$.attr("disabled", "disabled").addClass("disabled");
          }

          return;
        }

        if (this.tryShowPrice(el$, results)) {
          return;
        }

        el$.addClass("esc-product-pricing--on-request");
      });
    });
  }

  private static tryShowPrice(el$: JQuery<Element>, results: ProductPriceResultDto[]) {
    const productId = parseInt(el$.data("productid") || "0");

    if (productId > 0 && results) {
      const productResult = results.filter((r) => r.productId === productId)[0];

      if (productResult && productResult.price != null) {
        const priceElement = el$.find(".js-product-pricing__price");
        priceElement.html(productResult.price || "");

        return true;
      }
    }

    return false;
  }

  private static getProductPrices(
    productIdList: Array<number>,
    successCallback: (httpStatusCode: number, result: Array<ProductPriceResultDto>) => void,
  ) {
    axios
      .post("/api/pricing/fetch", productIdList)
      .then((result) => {
        ////console.log(result);
        successCallback(result.status, result.data);
      })
      .catch(axiosX.defaultCatch);
  }
}
